import { MantineTheme } from '@mantine/core';

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');
};

const getUserInfo = () => {
  const userInfo = localStorage.getItem('user');
  if (userInfo !== null) {
    return JSON.parse(userInfo);
  }
  return {};
};

const getRuleEvalStatusColor = (theme: MantineTheme, status: string) => {
  const RuleEvalStatusColor: Record<string, string> = {
    '10': theme.colors.gray[9],
    '20': theme.colors.green[9],
    '30': theme.colors.red[9],
  };

  const RuleEvalStatusSecondaryColor: Record<string, string> = {
    '10': theme.colors.gray[1],
    '20': theme.colors.green[1],
    '30': theme.colors.red[1],
  };

  return {
    primary: RuleEvalStatusColor[status],
    seconday: RuleEvalStatusSecondaryColor[status],
  };
};

const transformDateString = (
  dateString?: string,
  showTime: boolean = false,
  use12HourFormat: boolean = false
): string => {
  try {
    if (!dateString) {
      throw new Error('No date provided');
    }
    // Create a Date object from the input date string
    const dateObj = new Date(dateString);

    // Check if the date is valid
    if (isNaN(dateObj.getTime())) {
      throw new Error('Invalid date');
    }

    // Format the date into a more readable format
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...(showTime && {
        hour: 'numeric',
        minute: 'numeric',
        hour12: use12HourFormat,
      }),
    };

    const readableDate = dateObj.toLocaleDateString('en-US', options);

    return readableDate;
  } catch (error) {
    return 'Invalid date';
  }
};

const getUsernameFromEmail = (email: string) => {
  const atIndex = email.indexOf('@');
  if (atIndex !== -1) {
    return email.substring(0, atIndex);
  }
  return '';
};
const getHashByKey = <T extends Record<string, any>>(
  array: T[],
  key: keyof T,
  defaultKey: string = 'NA'
) => {
  const hashMap: Record<string, T[]> = {};
  array.forEach((item) => {
    const keyName = item[key] || defaultKey;
    if (!hashMap[keyName]) {
      hashMap[keyName] = [];
    }
    hashMap[keyName].push(item);
  });

  return hashMap;
};

const bytesToMB = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

const MBToBytes = (megabytes: number): number => {
  return megabytes * 1024 * 1024;
};

type ExecTreeNode = {
  exec_explanation?: string;
  exec_explanation_ctx?: {
    is_llm_generated?: boolean;
  };
  [key: string]: any;
};

function getRationalesFromExecTree(execTree: ExecTreeNode): string[] {
  if (typeof execTree !== 'object' || execTree === null) {
    return [];
  }

  const llmExplanations: string[] = [];

  if (
    execTree.exec_explanation &&
    execTree.exec_explanation_ctx &&
    execTree.exec_explanation_ctx.is_llm_generated
  ) {
    llmExplanations.push(execTree.exec_explanation);
  }

  for (const key in execTree) {
    if (typeof execTree[key] === 'object' && execTree[key] !== null) {
      llmExplanations.push(...getRationalesFromExecTree(execTree[key]));
    }
  }

  return llmExplanations;
}

const createInitialsIcon = (initials: string) => {
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="19" fill="#CD307D" />
      <text x="20" y="27" font-family="Arial" font-size="18" fill="white" text-anchor="middle">${initials}</text>
    </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

const getInitialsFromNames = (firstName: string, lastName: string) => {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  return `${firstInitial}${lastInitial}`;
};

export {
  getInitials,
  getInitialsFromNames,
  getUserInfo,
  transformDateString,
  getUsernameFromEmail,
  getHashByKey,
  bytesToMB,
  MBToBytes,
  getRuleEvalStatusColor,
  getRationalesFromExecTree,
  createInitialsIcon,
};
