import { PageClusterWordMap, TNestedblock, Words } from 'src/types/extractorTypes';

import {
  BT_REGISTRY,
  Block,
  BoundingBox,
  Document,
  FontStyle,
  Line,
  Page,
  Relationship,
  _WithContext,
} from './core';

class Skip extends Error {}

type SerializeFuncType = (obj: any, value: any) => any;
type DeserializeFuncType = (value: any, ctx: Record<string, any>) => any;

class SKey {
  name: string;
  serializeFunc?: SerializeFuncType;
  deserializeFunc?: DeserializeFuncType;

  constructor(
    name: string,
    serializeFunc?: SerializeFuncType,
    deserializeFunc?: DeserializeFuncType
  ) {
    this.name = name;
    this.serializeFunc = serializeFunc;
    this.deserializeFunc = deserializeFunc;
  }

  static skipSerialization(): never {
    throw new Skip();
  }

  static skipDeserialization(): never {
    throw new Skip();
  }
}

abstract class BaseSerializer {
  static SERIALIZATION_VERSION: string;
  protected _serializable_keys_map: Record<string, SKey[]> = {};

  serialize(obj: Block | Document): Record<string, any> {
    return this._serialize_recursive(obj);
  }

  private _serialize_recursive(obj: any): Record<string, any> {
    const classes = Object.keys(this._serializable_keys_map);
    const serializableKeys = this._serializable_keys_map[obj.constructor.name];

    const json: Record<string, any> = {};
    for (const key of serializableKeys) {
      let value = obj[key.name];
      try {
        if (key.serializeFunc) {
          value = key.serializeFunc(obj, value);
        } else if (classes.includes(value.constructor.name)) {
          value = this.serialize(value);
        } else if (Array.isArray(value)) {
          value = value.map((v) =>
            classes.includes(v.constructor.name) ? this.serialize(v) : v
          );
        }
      } catch (e) {
        if (e instanceof Skip) continue;
        throw e;
      }

      json[key.name] = value;
    }
    return json;
  }

  abstract deserialize(
    data: Record<string, any>,
    ctx?: Record<string, any>
  ): Document;

  protected _clean_for_deserialization(
    data: Record<string, any>,
    ctx: Record<string, any>,
    serializableKeys: SKey[]
  ): Record<string, any> {
    const finalAttrs: Record<string, any> = {};
    const serKeysDict = Object.fromEntries(
      serializableKeys.map((key) => [key.name, key])
    );
    for (const [key, value] of Object.entries(data)) {
      if (!(key in serKeysDict)) continue;

      if (serKeysDict[key].deserializeFunc) {
        try {
          finalAttrs[key] = serKeysDict[key].deserializeFunc!(value, ctx);
        } catch (e) {
          if (e instanceof Skip) continue;
          throw e;
        }
      } else {
        finalAttrs[key] = value;
      }

      if (finalAttrs[key] instanceof _WithContext) {
        finalAttrs[key].rootDocument = ctx['root_document'];
      }
    }
    return finalAttrs;
  }

  protected _generate_deserializer(
    serializableKeysMap: Record<string, SKey[]>,
    returnCls: { new (): any; displayName: string }
  ): DeserializeFuncType {
    return (data: Record<string, any>, ctx: Record<string, any>): any => {
      if (data === null) return null;
      const attrs = this._clean_for_deserialization(
        data,
        ctx,
        serializableKeysMap[returnCls.displayName] || []
      );
      const obj = new returnCls();
      Object.assign(obj, attrs);
      if (obj instanceof _WithContext) {
        obj.rootDocument = ctx['root_document'];
      }
      return obj;
    };
  }
}

// abstract class V0Serializer extends BaseSerializer {
//   static SERIALIZATION_VERSION = 'v0';
// }

class V1Serializer extends BaseSerializer {
  static SERIALIZATION_VERSION = 'v1';

  private static _block_skeys_default = [
    new SKey('id'),
    new SKey('block_type', undefined, SKey.skipDeserialization),
    new SKey('rotation'),
    new SKey('page_number'),
    new SKey('bounding_box', undefined, (d, c) =>
      V1Serializer.deserialize_bounding_box(d, c)
    ),
    new SKey('relationships', undefined, (d, c) =>
      V1Serializer.deserialize_relationships(d, c)
    ),
  ];

  protected _serializable_keys_map: Record<string, SKey[]> = {
    FontStyle: [
      new SKey('font_size'),
      new SKey('font_weight'),
      new SKey('font_name'),
      new SKey('font_family'),
      new SKey('font_color'),
      new SKey('text_decoration'),
      new SKey('font_style'),
    ],
    BoundingBox: [
      new SKey('x_left'),
      new SKey('y_top'),
      new SKey('x_right'),
      new SKey('y_bottom'),
    ],
    Relationship: [new SKey('name'), new SKey('target_id')],
    Block: V1Serializer._block_skeys_default,
    Page: [...V1Serializer._block_skeys_default],
    Cluster: [...V1Serializer._block_skeys_default],
    Line: [
      ...V1Serializer._block_skeys_default,
      new SKey('cluster_sequence_id'),
    ],
    Word: [
      ...V1Serializer._block_skeys_default,
      new SKey('text'),
      new SKey('font_style', undefined, (d, c) =>
        V1Serializer.deserialize_font_style(d, c)
      ),
    ],
    Image: [
      ...V1Serializer._block_skeys_default,
      new SKey('resolution'),
      new SKey('format'),
      new SKey(
        'image_bytes',
        () => [],
        (v) => new Uint8Array(v)
      ),
    ],
    Document: [new SKey('version'), new SKey('blocks')],
  };

  deserialize(data: Record<string, any>, ctx?: Record<string, any>): Document {
    const doc = new Document();
    doc.version = data.version || V1Serializer.SERIALIZATION_VERSION;
    const pageClusterWordMap: PageClusterWordMap = {};
    ctx = ctx || {};
    ctx['root_document'] = doc;

    const _block_precedence: Record<string, number> = {
      page: 10,
      word: 20,
      line: 30,
      image: 50,
    };

    const jsonBlocks = (data.blocks || []).sort((a: any, b: any) => {
      const precedenceA = _block_precedence[a.block_type] || 1000;
      const precedenceB = _block_precedence[b.block_type] || 1000;
      if (precedenceA !== precedenceB) return precedenceA - precedenceB;
      if (a.page_number !== b.page_number)
        return (a.page_number || 0) - (b.page_number || 0);
      return (a.id || '').localeCompare(b.id || '');
    });

    const _all_blocks: Block[] = [];
    const _blocks_by_type: Record<string, Block[]> = {};
    const pageBlocks: Record<string, Block> = {};

    for (const jsonBlock of jsonBlocks) {
      if (doc.idToBlock[jsonBlock.id]) {
        throw new Error(
          `Invalid JSON. Block with id ${jsonBlock.id} appeared more than once.`
        );
      }

      const block = this._deserialize_block(jsonBlock, ctx);

      doc.idToBlock[block.id] = block;
      _all_blocks.push(block);
      (_blocks_by_type[block.blockType] =
        _blocks_by_type[block.blockType] || []).push(block);
      if (block instanceof Page) {
        pageBlocks[block.page_number] = block;
      }

    }

    if (doc.version === 'v1') {
      // v1 uses cluster_sequence_id to group lines into clusters
      // whereas v2 has cluster blocks which contain lines
      // so for backwards compatibility we need to group lines 
      // into clusters for v1 as well
      for (const pageNumber in pageBlocks) {
        const page = pageBlocks[pageNumber];
        const clusterLineRels: Record<string, Words[]> = {};

        for (const block of page.getRelatedBlocks()) {
          if (block instanceof Line) {
            const clusterSequenceId = block.cluster_sequence_id;
            if (!clusterLineRels[clusterSequenceId]) {
              clusterLineRels[clusterSequenceId] = [];
            }
            clusterLineRels[clusterSequenceId].push(block.getWords(true) as any);
          }
        }

        const children: TNestedblock[] = [];

        for (const clusterSequenceId in clusterLineRels) {
          children.push({
            id: `page-${page.page_number}-cluster-${clusterSequenceId}`,
            children: clusterLineRels[clusterSequenceId]
          });
        }

        pageClusterWordMap[page.page_number.toString()] = children;
      }
    } else {

      for (const pageNumber in pageBlocks) {
        const page = pageBlocks[pageNumber];
        const children: TNestedblock[] = [];

        for (const block of page.getRelatedBlocks()) {
          const getNestedStructure = (block: Block): any => {
            if (!block) {
              return "";
            }
            if (block instanceof Line) {
              return block.getWords(true) as any;
            }
            
            const relatedBlocks = block.getRelatedBlocks();
            if (relatedBlocks.length === 0) {
              return "";
            }
            const children = relatedBlocks.map(getNestedStructure);
            return {
              id: block.id,
              children: children
            };
          };

          children.push(getNestedStructure(block));
        }

        pageClusterWordMap[page.page_number.toString()] = children;
      }
    }

    doc.pages = _blocks_by_type[Page.name] || [];
    doc.pageNumberToPage = Object.fromEntries(
      doc.pages.map((p) => [p.page_number, p])
    );
    doc.pageClusterWordMap = pageClusterWordMap;

    return doc;
  }

  private _deserialize_block(
    data: Record<string, any>,
    ctx: Record<string, any>
  ): Block {
    const rootDoc: Document = ctx['root_document'];
    const returnCls = BT_REGISTRY.getBlockType(data.block_type);
    const serializableKeys =
      this._serializable_keys_map[returnCls.displayName] || [];
    const finalAttrs = this._clean_for_deserialization(
      data,
      ctx,
      serializableKeys
    );
    const obj = new returnCls();
    Object.assign(obj, finalAttrs);
    obj.rootDocument = rootDoc;
    return obj;
  }

  static deserialize_font_style(
    data: Record<string, any>,
    ctx: Record<string, any>
  ): FontStyle {
    const serializableKeysMap = new V1Serializer()._serializable_keys_map;
    const serializeFunc = V1Serializer.prototype._generate_deserializer(
      serializableKeysMap,
      FontStyle
    );
    return serializeFunc(data, ctx);
  }

  static deserialize_bounding_box(
    data: Record<string, any>,
    ctx: Record<string, any>
  ): BoundingBox {
    const serializableKeysMap = new V1Serializer()._serializable_keys_map;
    const serializeFunc = V1Serializer.prototype._generate_deserializer(
      serializableKeysMap,
      BoundingBox
    );
    return serializeFunc(data, ctx);
  }

  static deserialize_relationships(
    data: Record<string, any>[],
    ctx: Record<string, any>
  ): Relationship[] {
    const serializableKeysMap = new V1Serializer()._serializable_keys_map;
    const serializeFunc = V1Serializer.prototype._generate_deserializer(
      serializableKeysMap,
      Relationship
    );
    return data.map((val) => serializeFunc(val, ctx));
  }
}

const Serializer = new V1Serializer();

export { Serializer, V1Serializer };
