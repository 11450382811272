import { request, generateCRUDOps } from './httpService';

const pathPrefix = `/api/v1/`;

const AuditService = {
  ...generateCRUDOps(pathPrefix, 'logs'),
  getContentTypes: async () => {
    return request({
      url: `${pathPrefix}meta/content-types/`,
      method: 'get',
    });
  },
};

export default AuditService;
