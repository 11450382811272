import { LoadingStatus } from 'Constants/index';

export const handleAsyncActions = (
  builder: any,
  actionType: string,
  stateKey: string,
  errorKey: string,
  dataKey?: string
) => {
  builder
    .addCase(`${actionType}/pending`, (state: any) => {
      state[stateKey] = LoadingStatus.LOADING;
    })
    .addCase(`${actionType}/fulfilled`, (state: any, action: any) => {
      state[stateKey] = LoadingStatus.SUCCESS;
      if (dataKey) {
        if (state.data) state.data[dataKey] = action.payload;
      } else {
        state.data = action.payload;
      }
    })
    .addCase(`${actionType}/rejected`, (state: any, action: any) => {
      state[stateKey] = LoadingStatus.ERROR;
      state[errorKey] = action.payload as string;
    });
};
