import { Button, Popover } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { FilterConfigTypes } from 'Types/commonTypes';

interface AddFilterButtonProps {
  filtersConfig: FilterConfigTypes[];
  activeFilters: string[];
  addFilter: (filterKey: string) => void;
  openFilterPopover: string | null;
  setOpenFilterPopover: (key: string | null) => void;
}

const AddFilterButton: React.FC<AddFilterButtonProps> = ({
  filtersConfig,
  activeFilters,
  addFilter,
  openFilterPopover,
  setOpenFilterPopover,
}) => {
  return (
    <Popover
      position="bottom"
      shadow="md"
      offset={0}
      opened={openFilterPopover === 'addFilter'}
      onClose={() => setOpenFilterPopover(null)}
    >
      <Popover.Target>
        <Button
          variant="transparent"
          size="xs"
          leftSection={<IconPlus size={14} />}
          onClick={() =>
            setOpenFilterPopover(
              openFilterPopover === 'addFilter' ? null : 'addFilter'
            )
          }
        >
          Add filter
        </Button>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        {filtersConfig
          .filter((f) => !activeFilters.includes(f.key))
          .map((filter) => (
            <Button
              key={filter.key}
              variant="subtle"
              fullWidth
              size="xs"
              styles={() => ({
                inner: {
                  justifyContent: 'flex-start',
                },
                label: {
                  flex: 1,
                  textAlign: 'left',
                },
              })}
              onClick={() => addFilter(filter.key)}
            >
              {filter.label}
            </Button>
          ))}
      </Popover.Dropdown>
    </Popover>
  );
};

export default AddFilterButton;
