import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Flex, SegmentedControl } from '@mantine/core';
import SnapshotService from 'Api/snapshotService';
import Drawer from 'Components/Drawer/Drawer';
import FileViewer from 'Components/file-viewer/FileViewer';
import { fetchActiveDocument } from 'Slices/activeDocumentSlice';
import { SnapshotDisplayMode } from 'Src/constants/index';
import { AppDispatch, RootState } from 'Src/redux/store';
import { DocumentSnapshotType } from 'Types/docTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

import classes from '../Assessment.module.scss';
import SelectSnapshotVersions from '../components/SelectSnapshotVersions';
import SnapshotActions from '../components/compare-snapshots/SnapshotActions';
import AssessmentPreview from './compare-snapshots/AssessmentPreview';

type CompareSnapshotModalProps = {
  opened: boolean;
  onClose: () => void;
  snapshotForCompare: DocumentSnapshotType;
  primarySnapshot: DocumentSnapshotType;
  setSelectedSnapshot: (value?: DocumentSnapshotType) => void;
  setSnapshotVersions: (value: DocumentSnapshotType[]) => void;
  refreshAssessment: () => void;
};

const getVersionsToCompare = (
  currentSnapshot: DocumentSnapshotType,
  primarySnapshot: DocumentSnapshotType
) => {
  if (!currentSnapshot || !primarySnapshot) return [];
  return [
    {
      label: `${transformDateString(primarySnapshot.created_at, true)} (Primary)`,
      value: primarySnapshot?.id.toString(),
    },
    {
      label: transformDateString(currentSnapshot.created_at, true),
      value: currentSnapshot?.id.toString(),
    },
  ];
};

const CompareSnapshotModal: React.FC<CompareSnapshotModalProps> = ({
  opened,
  onClose,
  snapshotForCompare,
  primarySnapshot,
  setSelectedSnapshot,
  setSnapshotVersions,
}) => {
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const snapshotVersions = documentData?.snapshots || [];
  const dispatch = useDispatch<AppDispatch>();
  const [snapshotDisplayMode, setSnapshotDisplayMode] = useState(
    SnapshotDisplayMode.PREVIEW
  );
  const [currentSnapshot, setCurrentSnapshot] = useState(snapshotForCompare);
  const versionToCompare = getVersionsToCompare(
    snapshotForCompare,
    primarySnapshot
  );

  const onClosePreview = () => {
    setSnapshotDisplayMode(SnapshotDisplayMode.PREVIEW);
    onClose();
  };

  const onCompareSnapshots = () => {
    setCurrentSnapshot(primarySnapshot);
    setSnapshotDisplayMode(SnapshotDisplayMode.COMPARE);
  };

  const onVersionsSwitch = (id: string) => {
    const newSnapshot =
      Number(id) === primarySnapshot?.id ? primarySnapshot : snapshotForCompare;
    setCurrentSnapshot(newSnapshot);
  };

  const refetchAssessment = () => {
    onClosePreview();
    if (documentData?.id) dispatch(fetchActiveDocument(documentData.id));
  };

  const makeItPrimary = async () => {
    if (!currentSnapshot?.id) return;
    try {
      await SnapshotService.markSnapshotAsPrimary(currentSnapshot?.id, true);
      setSelectedSnapshot(currentSnapshot);
      refetchAssessment();
    } catch (e: any) {
      showErrorNotification(
        e.message || 'Error making the snapshot as primary'
      );
    }
  };

  const deleteSnapshotVersion = (deletedSnapshotId: string) => {
    const updatedSnapshots = snapshotVersions.filter(
      (snapshot) => snapshot.id.toString() !== deletedSnapshotId
    );
    setSnapshotVersions(updatedSnapshots);
    refetchAssessment();
  };

  useEffect(() => {
    if (snapshotForCompare?.id) {
      setCurrentSnapshot(snapshotForCompare);
      setSnapshotDisplayMode(SnapshotDisplayMode.PREVIEW);
    }
  }, [snapshotForCompare]);

  type SnapshotActionItemsProps = {
    position: string;
  };

  const SnapshotActionItems: React.FC<SnapshotActionItemsProps> = ({
    position,
  }) => {
    return (
      <SnapshotActions
        primarySnapshotId={primarySnapshot?.id}
        snapshotId={currentSnapshot.id}
        makeItPrimary={makeItPrimary}
        compareSnapshots={onCompareSnapshots}
        position={position}
        onClosePreview={onClosePreview}
        deleteSnapshotVersion={deleteSnapshotVersion}
        showCompareAction={SnapshotDisplayMode.PREVIEW === snapshotDisplayMode}
      />
    );
  };

  if (!snapshotForCompare) return;

  return (
    <Drawer
      opened={opened}
      onClose={onClosePreview}
      size="85%"
      position="right"
      withinPortal={false}
      keepMounted={false}
      withCloseButton={false}
      lockScroll
      className={classes.drawer}
      offsetFromHeader
    >
      <Drawer.Body p="md">
        <Grid p="0" pos="relative">
          <Grid.Col span={7}>
            <Flex direction="column" pr="md">
              <Flex mb="sm" justify="space-between" w="100%" align="center">
                {snapshotDisplayMode === SnapshotDisplayMode.COMPARE ? (
                  <React.Fragment>
                    <SegmentedControl
                      value={currentSnapshot.id.toString()}
                      onChange={onVersionsSwitch}
                      data={versionToCompare}
                    />
                    <SelectSnapshotVersions
                      selectedSnapshot={snapshotForCompare.id?.toString()}
                      snapshotVersions={snapshotVersions}
                      setSnapshotVersions={setSnapshotVersions}
                      setSelectedSnapshot={setSelectedSnapshot}
                      showActions={false}
                      selectViewType="select"
                      primarySnapshot={primarySnapshot}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <SnapshotActionItems position="header" />
                  </React.Fragment>
                )}
              </Flex>
              <FileViewer
                height="82vh"
                fileUrl={currentSnapshot?.file || snapshotForCompare?.file}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={5}>
            <AssessmentPreview
              currentSnapshot={currentSnapshot}
              onClosePreview={onClosePreview}
              footerActions={<SnapshotActionItems position="footer" />}
            />
          </Grid.Col>
        </Grid>
      </Drawer.Body>
    </Drawer>
  );
};

export default CompareSnapshotModal;
