import {
  Button,
  Popover,
  Text,
  Group,
  Select,
  MultiSelect,
} from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import {
  FilterConfigTypes,
  FilterValue,
  GenericFilterConfig,
} from 'Types/commonTypes';

interface FilterPopoverProps<T extends Record<string, FilterValue>> {
  filter: FilterConfigTypes;
  selectedValues: FilterValue;
  filtersConfig: GenericFilterConfig<T[keyof T]>[];
  handleFilterChange: (
    filterKey: string,
    value: any,
    closePopUp?: boolean
  ) => void;
  removeFilter: (filterKey: string) => void;
  showAllByDefault: boolean;
  openFilterPopover: string | null;
  setOpenFilterPopover: (key: string | null) => void;
}

const FilterPopover = <T extends Record<string, FilterValue>>({
  filtersConfig,
  filter,
  selectedValues,
  handleFilterChange,
  removeFilter,
  showAllByDefault,
  openFilterPopover,
  setOpenFilterPopover,
}: FilterPopoverProps<T>) => {
  const renderFilterLabel = (value: any, filterKey: string) => {
    const filter = filtersConfig?.find(
      (f) => f.key === filterKey
    ) as FilterConfigTypes;
    if (!filter) return '';

    let label = '';
    if (Array.isArray(value)) {
      if (filter.component === DatePicker) {
        const formatDate = (dateString: string | null) => {
          if (!dateString) return '';
          const date = new Date(dateString);
          return isNaN(date.getTime()) ? '' : date.toLocaleDateString();
        };
        const [start, end] = value;
        label = start && end ? `${formatDate(start)} - ${formatDate(end)}` : '';
      } else {
        label =
          value.length > 1
            ? `${value.length} selected`
            : value[0]?.toString() || '';
      }
    } else if (value instanceof Date) {
      label = value.toLocaleDateString();
    } else {
      label = value?.toString() || '';
    }

    // label = label.length > MAX_LABEL_LENGTH ? label.slice(0, 12) + '...' : label;
    return label ? `: ${label}` : label;
  };

  const renderPopoverContent = () => {
    const Component = filter.component;
    const commonProps = {
      ...filter.props,
      onChange: (value: any) => handleFilterChange(filter.key, value as any),
      clearable: true,
      onClear: () => {
        removeFilter(filter.key);
        setOpenFilterPopover(null);
      },
    };

    switch (Component) {
      case DatePicker:
        return (
          <DatePicker
            type="range"
            value={(selectedValues as [DateValue, DateValue]) || [null, null]}
            onChange={(value: [DateValue, DateValue]) => {
              const closePopUp = value[0] && value[1] ? true : false;
              handleFilterChange(filter.key, value, closePopUp);
            }}
            allowSingleDateInRange
          />
        );

      case Select:
        return (
          <Select
            data={filter.props.data}
            // @ts-expect-error - TODO: Fix these type issues
            value={(selectedValues as string) || null}
            {...commonProps}
          />
        );

      case MultiSelect:
        return (
          <MultiSelect
            data={filter.props.data}
            // @ts-expect-error - TODO: Fix these type issues
            value={Array.isArray(selectedValues) ? selectedValues : []}
            {...commonProps}
          />
        );

      default:
        // Can accommodate other components like input, etc.
        return null;
    }
  };

  return (
    <Popover
      position="bottom"
      shadow="md"
      width={filter.component === DatePicker ? 300 : 220}
      closeOnClickOutside
      opened={openFilterPopover === filter.key}
      onClose={() => setOpenFilterPopover(null)}
    >
      <Popover.Target>
        <Button
          variant="default"
          style={{ justifyContent: 'flex-start' }}
          size="xs"
          onClick={() =>
            setOpenFilterPopover(
              openFilterPopover === filter.key ? null : filter.key
            )
          }
        >
          <Text size="xs" mr={'4px'} fw={selectedValues ? 600 : 500}>
            {filter.label}
          </Text>
          <Text size="xs" mr="xs" truncate="end" maw={80}>
            {renderFilterLabel(selectedValues, filter.key)}
          </Text>
          <IconChevronDown size={14} style={{ flexShrink: 0 }} />
        </Button>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <Group justify="space-between" mb="xs">
          <Text size="xs" fw={500}>
            {filter.label}
          </Text>
          {!showAllByDefault && (
            <IconX size={14} onClick={() => removeFilter(filter.key)} />
          )}
        </Group>
        {renderPopoverContent()}
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterPopover;
