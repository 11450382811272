import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Group, Box, Collapse, Text, UnstyledButton, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { MenuItemType } from 'Types/commonTypes';

import classes from './NavbarLinksGroup.module.scss';

type LinksGroupProps = MenuItemType & {
  initiallyOpened?: boolean;
  activeMenu?: string;
  handleMenuClick: (menuSlug: string) => void;
  nestedLinks?: MenuItemType[];
};

const NavbarLinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  slug,
  link,
  nestedLinks,
  handleMenuClick,
  activeMenu,
}: LinksGroupProps) => {
  const hasLinks = !!nestedLinks?.length;
  const [opened, setOpened] = useState(initiallyOpened || false);

  const items = (hasLinks ? nestedLinks : undefined)?.map((item) => (
    <NavbarLinksGroup
      handleMenuClick={handleMenuClick}
      activeMenu={activeMenu}
      key={item.slug}
      {...item}
    />
  ));

  const NavLinkButton = () => {
    return (
      <Group justify="space-between" gap={0}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Icon style={{ color: 'gray' }} />
          <Text size="sm" className={classes.link} key={label}>
            {label}
          </Text>
        </Box>
        {hasLinks && (
          <IconChevronRight
            className={classes.chevron}
            stroke={1.5}
            style={{
              width: rem(16),
              height: rem(16),
              transform: opened ? 'rotate(-90deg)' : 'none',
            }}
          />
        )}
      </Group>
    );
  };

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={[
          classes.control,
          slug === activeMenu ? classes.active : '',
        ].join(' ')}
      >
        {hasLinks ? (
          <NavLinkButton />
        ) : link ? (
          <NavLink
            className={classes.navLinks}
            onClick={() => slug && handleMenuClick(slug)}
            key={slug}
            to={link}
          >
            <NavLinkButton />
          </NavLink>
        ) : null}
      </UnstyledButton>
      {hasLinks ? (
        <Collapse in={opened} className={classes.nestedLinks}>
          {items}
        </Collapse>
      ) : null}
    </>
  );
};

export default NavbarLinksGroup;
