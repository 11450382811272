import React from 'react';

import {
  Select,
  Flex,
  Text,
  Group,
  ActionIcon,
  Button,
  Menu,
  Stack,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconVersions, IconSelector } from '@tabler/icons-react';
import { DocumentSnapshotType } from 'Types/docTypes';
import { transformDateString } from 'Utils/transform';

import DeleteAction from '../components/compare-snapshots/DeleteActions';

interface SelectSnapshotVersionsProps {
  snapshotVersions: DocumentSnapshotType[];
  selectedSnapshot?: string;
  setSnapshotVersions: (value: DocumentSnapshotType[]) => void;
  setSelectedSnapshot: (value?: DocumentSnapshotType) => void;
  showActions?: boolean;
  primarySnapshot?: DocumentSnapshotType;
  selectViewType?: string;
}

const SelectSnapshotVersions: React.FC<SelectSnapshotVersionsProps> = ({
  snapshotVersions,
  selectedSnapshot,
  setSelectedSnapshot,
  setSnapshotVersions,
  showActions = true,
  primarySnapshot,
  selectViewType = 'select',
}) => {
  const [dropdownOpened, handleDropdown] = useDisclosure();
  const [menuOpened, handleMenu] = useDisclosure(false);

  const updateSnapshotVersions = (deletedSnapshotId: string) => {
    const updatedSnapshots = snapshotVersions.filter(
      (snapshot) => snapshot.id.toString() !== deletedSnapshotId
    );
    setSnapshotVersions(updatedSnapshots);
  };

  const nonPrimaryVersions = snapshotVersions.filter(
    (option) => option.id !== primarySnapshot?.id
  );

  return (
    <React.Fragment>
      {selectViewType === 'select' ? (
        <Select
          miw={showActions ? 300 : 200}
          leftSection={<IconVersions />}
          placeholder="Choose Snapshot"
          onClick={handleDropdown.toggle}
          onBlur={handleDropdown.close}
          dropdownOpened={dropdownOpened}
          data={nonPrimaryVersions.map((snapshot) => ({
            label: transformDateString(snapshot.created_at, true),
            value: snapshot.id.toString(),
          }))}
          allowDeselect={false}
          onChange={(value) => {
            if (!value) return;
            const snapshot = snapshotVersions.find(
              (snap) => snap.id.toString() === value
            );
            setSelectedSnapshot(snapshot);
            handleDropdown.close();
          }}
          renderOption={({ option, checked }) => (
            <Flex w="100%" justify="space-between">
              <Text size="sm" ml="sm" fw={checked ? 700 : undefined}>
                {option.label}
              </Text>
              {!showActions || option.value === selectedSnapshot ? null : (
                <Group gap={5}>
                  <DeleteAction
                    size="xs"
                    variant="transparent"
                    color="primary"
                    optionValue={option.value}
                    callbackOnComplete={updateSnapshotVersions}
                    callbackOnClick={(event) => {
                      event.stopPropagation();
                      handleDropdown.close();
                    }}
                  />
                </Group>
              )}
            </Flex>
          )}
          value={selectedSnapshot}
        />
      ) : (
        <Menu
          shadow="md"
          offset={0}
          opened={menuOpened}
          onChange={handleMenu.toggle}
        >
          <Menu.Target>
            <Button
              variant="default"
              rightSection={
                <ActionIcon size="sm" variant="transparent" color="black">
                  <IconSelector stroke={1.5} />
                </ActionIcon>
              }
            >
              {snapshotVersions.length} Snapshot versions
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label></Menu.Label>
            <Stack gap="0" pl="xs" pb="xs">
              <Text size="sm">
                {primarySnapshot &&
                  transformDateString(primarySnapshot?.created_at, true)}
              </Text>
              <Text size="xs" c="primary">
                Primary
              </Text>
            </Stack>

            <Menu.Divider />
            {nonPrimaryVersions.map((option) => (
              <Menu.Item
                key={option.id}
                rightSection={
                  <DeleteAction
                    optionValue={option?.id.toString()}
                    callbackOnComplete={updateSnapshotVersions}
                    callbackOnClick={(event) => {
                      event.stopPropagation();
                      handleMenu.close();
                    }}
                  />
                }
                onClick={() => {
                  setSelectedSnapshot(option);
                }}
              >
                {transformDateString(option.created_at, true)}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </React.Fragment>
  );
};

export { DeleteAction };
export default SelectSnapshotVersions;
