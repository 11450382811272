import { snakeCase } from 'lodash';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Card,
  useMantineTheme,
  Tooltip,
  ActionIcon,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconPlus, IconHistory } from '@tabler/icons-react';
import RuleService from 'Api/ruleService';
import AuditLogs from 'Src/components/activity-log/AuditLogs';
import useLoading from 'Src/hooks/useLoading';
import { FiltersType } from 'Types/commonTypes';
import { RuleType, TagType } from 'Types/ruleTypes';
import { showErrorNotification } from 'Utils/notifications';
import debounce from 'lodash/debounce';

import AddRuleDrawer from './components/AddRuleDrawer';
import ListRules from './components/ListRules';

export default function Rules() {
  const [rules, setRules] = useState([]);
  const [activeRuleGroup, setActiveRuleGroup] = useState<string>('All Rules');
  const [openAddRuleDrawer, setOpenAddRuleDrawer] = useState(false);
  const [ruleToView, setRuleToView] = useState<RuleType | null>(null);
  const [ruleTags, setRuleTags] = useState<TagType[]>([]);
  const [loadingRules, handleLoading] = useLoading(true);
  const [totalRules, setTotalRules] = useState(0);
  const [page, setPage] = useState<number>(1);
  const theme = useMantineTheme();
  const [loadingTags, handleLoadingTags] = useLoading(true);
  const [currentAppliedFilters, setCurrentAppliedFilters] =
    useState<FiltersType>({});
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const [currentRuleId, setCurrentRuleId] = useState<number | null>(null);

  const fetcRuleTags = async () => {
    try {
      const response = await RuleService.getRuleTags();
      setRuleTags([
        { temp_group_name: 'All Rules', count: totalRules },
        ...response.data,
      ]);
    } catch (_) {
      showErrorNotification('Error while fetching rule tags');
    } finally {
      handleLoadingTags.stop();
    }
  };

  const fetchRules = async (
    options: { page?: number; filters?: FiltersType } = {}
  ) => {
    const { page = 1, filters = currentAppliedFilters } = options;
    handleLoading.start();
    try {
      const response = await RuleService.getRules({
        filters: {
          page,
          temp_group_name:
            activeRuleGroup === 'All Rules' ? '' : activeRuleGroup,
          ...filters,
        },
      });
      if (response && response.data) {
        const { results = [], count } = response.data;
        setRules(results);
        setTotalRules(count);
      }
    } catch (err: any) {
      showErrorNotification(err.message ?? 'Error while fetching rules');
    } finally {
      handleLoading.stop();
    }
  };

  const searchRule = debounce(async (text: string) => {
    setPage(1);
    setActiveRuleGroup('All Rules');
    if (!text || text.length == 0) {
      return fetchRules();
    }
    handleLoading.start();
    try {
      const response = await RuleService.searchRule(text);
      setRules(response.data);
    } catch (e) {
      showErrorNotification('Something went wrong while performing search');
    } finally {
      handleLoading.stop();
    }
  }, 300);

  const openFilledDrawer = (id: number) => {
    const ruleToView = rules.find((rule: RuleType) => rule.id === id);
    if (ruleToView) {
      setRuleToView(ruleToView);
      setOpenAddRuleDrawer(true);
    } else {
      notifications.show({
        title: 'Error',
        message: 'Rule not found',
        color: 'red',
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (totalRules && ruleTags.length === 0) await fetcRuleTags();
    })();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [totalRules]);

  useEffect(() => {
    fetchRules();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeRuleGroup]);

  const handleNewRule = () => {
    if (ruleToView) {
      setRuleToView(null);
    }
    setOpenAddRuleDrawer(true);
  };

  const onPageChange = (page: number) => {
    setPage(page);
    fetchRules({ page });
  };

  const onApplyFilters = (filters: FiltersType) => {
    const filtersEmpty = Object.keys(filters).length === 0;
    setCurrentAppliedFilters(filters);
    if (filtersEmpty) setPage(1);
    fetchRules({ filters });
  };

  useEffect(() => {
    if (currentRuleId) {
      setOpenAuditLogs(true);
    } else {
      setOpenAuditLogs(false);
    }
  }, [currentRuleId]);

  return (
    <SimpleGrid cols={1} mah="80vh">
      <Flex justify="space-between">
        <Title order={4}>Rules</Title>
        <Group>
          <Button
            leftSection={<IconPlus size={18} />}
            onClick={handleNewRule}
            variant="outline"
          >
            Create Rule
          </Button>
          <Tooltip label="Activity Log">
            <ActionIcon
              variant="light"
              aria-label="Settings"
              size="lg"
              onClick={() => {
                setOpenAuditLogs(true);
              }}
            >
              <IconHistory stroke={1.5} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Flex>
      <Paper radius={5} p="xs">
        <Grid pos="relative" w="100%" mah="80vh" p="0">
          <Grid.Col
            span={2}
            style={{
              borderRight: `1px solid ${theme.colors.gray[2]}`,
            }}
            p="0"
            pos="relative"
          >
            <LoadingOverlay
              visible={loadingTags}
              zIndex={1000}
              overlayProps={{ radius: 'sm', blur: 2 }}
              loaderProps={{ color: 'pink', type: 'bars' }}
            />
            <Flex>
              <Title py="md" px="md" order={5}>
                Filter by Tags
              </Title>
            </Flex>
            <Flex
              px="xs"
              direction="column"
              style={{ overflowY: 'scroll' }}
              mah="70vh"
            >
              <Box>
                {ruleTags
                  .filter((item) => item.temp_group_name)
                  .map((tag: TagType) => (
                    <Card
                      key={snakeCase(tag.temp_group_name)}
                      withBorder
                      style={{
                        backgroundColor:
                          activeRuleGroup === tag.temp_group_name
                            ? theme.colors.gray[1]
                            : 'inherit',
                        overflowY: 'scroll',
                        cursor: 'pointer',
                      }}
                      mb="xs"
                      p="xs"
                      onClick={() => {
                        setPage(1);
                        setActiveRuleGroup(tag.temp_group_name);
                      }}
                    >
                      <Group justify="space-between" align="flex-start">
                        <Group gap="xs" align="flex-start">
                          <Stack gap={5}>
                            <Text size="sm">{tag.temp_group_name}</Text>
                            <Text size="xs">{tag.count} Rules</Text>
                          </Stack>
                        </Group>
                      </Group>
                    </Card>
                  ))}
              </Box>
            </Flex>
          </Grid.Col>
          <Grid.Col span={10} w="100%" p="0">
            <ListRules
              openFilledDrawer={openFilledDrawer}
              rules={rules}
              onPageChange={onPageChange}
              loadingRules={loadingRules}
              totalRules={totalRules}
              page={page}
              onSearch={searchRule}
              onApplyFilters={onApplyFilters}
              openActivityLog={(ruleId: number) => {
                setCurrentRuleId(ruleId);
              }}
            />
          </Grid.Col>
        </Grid>
      </Paper>
      <AddRuleDrawer
        setRuleToView={setRuleToView}
        openAddRuleDrawer={openAddRuleDrawer}
        setOpenAddRuleDrawer={setOpenAddRuleDrawer}
        ruleToView={ruleToView}
        refreshRules={fetchRules}
      />
      <AuditLogs
        open={openAuditLogs}
        onClose={() => {
          setOpenAuditLogs(false);
          setCurrentRuleId(null);
        }}
        contentType={'rules.rule'}
        entities={{
          list: rules,
          key: 'title',
          currentEntity: currentRuleId ?? null,
          contentType: 'rules.rule',
          name: 'Rule',
        }}
      />
    </SimpleGrid>
  );
}
