import dayjs from 'dayjs';

const ColorCodeForExecutionStatus = {
  '10': 'orange',
  '20': 'green',
  '30': 'red',
  '40': 'yellow',
  '50': 'gray',
};

const RuleEvalStatus = {
  '10': 'PENDING',
  '20': 'APPROVED',
  '30': 'REJECTED',
  // '40': 'CANCELED',
  // '50': 'IGNORED',
};

const RuleEvalStatusCodes = {
  PENDING: '10',
  APPROVED: '20',
  REJECTED: '30',
  IGNORED: '50',
};

const LayoutVariant = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

const Assessment = {
  MAX_FILE_SIZE: 20, // in Mbs
};

const AssessmentTabs = {
  ASSESSMENT_TAB: 'assessment',
  SETUP_TAB: 'setup',
};

const RuleExecutionStatus = {
  NOT_STARTED: 'not_started',
  QUEUED: 'queued',
  EXECUTING: 'executing',
  IGNORED: 'ignored',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
};

const RuleEvaluationStatus = {
  PENDING: 'Pending',
};

const RULE_TAG_KEY = 'temp_group_name';

const RuleFilters = [
  {
    label: 'Rule Status',
    values: [
      { label: 'Inactive', value: 'false' },
      { label: 'Active', value: 'true' },
    ],
    key: 'is_active',
    multi: false,
    activeLabels: {
      '0': 'Inactive',
      '1': 'Active',
    },
  },
  {
    label: 'Assessment Type',
    values: [
      { label: 'Manual', value: 'manual' },
      { label: 'Automate', value: 'automate' },
    ],
    key: 'rule_type',
    multi: false,
  },
];

const SnapshotDisplayMode = {
  PREVIEW: 'preview',
  COMPARE: 'compare',
};

const Mode = {
  VIEW: 'view',
  EDIT: 'edit',
};

enum LoadingStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const LAST_2_DAYS_RANGE = [
  dayjs().subtract(2, 'day').startOf('day').toDate(),
  dayjs().endOf('day').toDate(),
];

export {
  ColorCodeForExecutionStatus,
  LayoutVariant,
  RuleEvalStatus,
  Assessment,
  AssessmentTabs,
  RuleExecutionStatus,
  RuleEvaluationStatus,
  RuleEvalStatusCodes,
  RULE_TAG_KEY,
  RuleFilters,
  SnapshotDisplayMode,
  Mode,
  LoadingStatus,
  LAST_2_DAYS_RANGE,
};
