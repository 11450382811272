import { UnstyledButton, Group, Avatar, Text } from '@mantine/core';
import { getInitials } from 'Src/utils/transform';

import classes from './Tenant.module.scss';

type TenantProps = {
  id: number;
  name: string;
  isSelected: boolean;
  setIsSelected: (id: number) => void;
};

const Tenant: React.FC<TenantProps> = ({
  name,
  id,
  isSelected,
  setIsSelected,
}) => {
  return (
    <>
      <div className={classes.root}>
        <UnstyledButton
          className={classes.control}
          data-checked={isSelected || undefined}
          onClick={() => setIsSelected(id)}
        >
          <Group>
            <Avatar color="violet" radius="xl">
              {getInitials(name)}
            </Avatar>
            <Text className={classes.label}>{name}</Text>
          </Group>
        </UnstyledButton>
      </div>
    </>
  );
};

export default Tenant;
