import React from 'react';

import { Text, Accordion } from '@mantine/core';
import { PageClusterWordMap } from 'Types/extractorTypes';

import DisplayNestedClusters from './DisplayNestedClusters';

type TextVisualizerProps = {
  documentText: PageClusterWordMap | string;
  highlightText: (coordinates: any) => void;
};

const TextVisualizer: React.FC<TextVisualizerProps> = ({
  documentText,
  highlightText,
}) => {
  if (typeof documentText === 'string') return documentText;
  return (
    <Accordion multiple defaultValue={['0']}>
      {documentText &&
        Object.keys(documentText).map((item) => {
          const pageData = documentText[item];
          const pageNumber = Number(item);

          return (
            <Accordion.Item value={item} key={item}>
              <Accordion.Control>
                <Text c="#757575">Page {pageNumber}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <DisplayNestedClusters
                  pageData={pageData}
                  highlightText={highlightText}
                  pageNumber={pageNumber}
                />
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
};

export default TextVisualizer;
