import { API_PATH, generateCRUDOps, request } from './httpService';

export const CommentThreadsService = {
  ...generateCRUDOps(API_PATH.REVIEWS, 'comment-threads'),
  addPost: async (commentThreadId: number, data: any) => {
    return request({
      url: `${API_PATH.REVIEWS}comment-threads/${commentThreadId}/add-post/`,
      method: 'post',
      data,
    });
  },
  addComments: async (commentThreadId: number, data: any) => {
    return request({
      url: `${API_PATH.REVIEWS}comment-threads/${commentThreadId}/add-comments/`,
      method: 'post',
      data,
    });
  },
};
