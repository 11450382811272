import { request } from './httpService';

const pathPrefix = '/api/v1/org-administration/';

const CoreService = {
  getOrgs: async () => {
    return request({
      url: `${pathPrefix}orgs/`,
      method: 'get',
    });
  },
};

export default CoreService;
