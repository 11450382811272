import cx from 'clsx';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  AppShell,
  Burger,
  Group,
  useMantineColorScheme,
  UnstyledButton,
  Menu,
  useMantineTheme,
} from '@mantine/core';
import { IconSun, IconMoon, IconLogout } from '@tabler/icons-react';
import { AuthService } from 'Api/authService';
import UserLabel from 'Components/user-label/UserLabel';
import { logout } from 'Slices/authSlice';
// import ZenafideLogo from 'Src/assets/zenafide-logo.png';
import { RootState } from 'Src/redux/store';
import { fetchLogo } from 'Src/theme.config';
import { BaseMenuItemType } from 'Types/commonTypes';

import classes from './Navbar.module.scss';

type NavBarProps = {
  handleDesktopOpened?: { toggle: () => void };
  desktopOpened?: boolean;
};

const NavBar: React.FC<NavBarProps> = ({
  handleDesktopOpened,
  desktopOpened,
}) => {
  const { setColorScheme, colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);

  const handleLogout = async () => {
    try {
      setUserMenuOpened(false);
      await AuthService.logout();
      dispatch(logout());
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const menuItems: BaseMenuItemType[] = [
    // { icon: IconSettings, text: 'Account settings', isDisabled: true },
    { icon: IconLogout, label: 'Logout', onClick: handleLogout },
  ];

  return (
    <AppShell.Header>
      <Group align="center" h="100%" px="md" justify="space-between">
        <Group align="center">
          {handleDesktopOpened && (
            <Burger
              opened={desktopOpened}
              onClick={handleDesktopOpened.toggle}
              visibleFrom="sm"
              size="sm"
            />
          )}
          <Group align="center">
            <img
              height={40}
              src={fetchLogo(user)}
              alt="Zenafide Logo"
              className={classes.logo}
            />
          </Group>
        </Group>
        <Group align="center" justify="space-around">
          <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: 'pop-top-right' }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
            withinPortal
          >
            {' '}
            <Menu.Target>
              <UnstyledButton
                className={cx(classes.user, {
                  [classes.userActive]: userMenuOpened,
                })}
              >
                <UserLabel />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              {menuItems.map((item, index) => (
                <Menu.Item
                  disabled={item.isDisabled}
                  key={index}
                  color={item.color}
                  leftSection={
                    <item.icon
                      className={classes.icon}
                      color={item.color || theme.colors.gray[6]}
                      stroke={1.5}
                    />
                  }
                  {...(item.onClick ? { onClick: item.onClick } : {})}
                >
                  {item.label}
                </Menu.Item>
              ))}
              <Menu.Item
                onClick={() =>
                  setColorScheme(colorScheme === 'dark' ? 'light' : 'dark')
                }
                leftSection={
                  <>
                    <IconSun
                      className={cx(classes.icon, classes.light)}
                      stroke={1.5}
                    />
                    <IconMoon
                      className={cx(classes.icon, classes.dark)}
                      stroke={1.5}
                    />
                  </>
                }
              >
                {colorScheme === 'dark' ? 'Light Mode' : 'Dark Mode'}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
    </AppShell.Header>
  );
};

export default NavBar;
