import { DataTableColumn } from 'mantine-datatable';

import { DocumentDataType } from 'Types/docTypes';

import {
  FilenameColumn,
  LastActivityColumn,
  SnapshotsColumn,
  CreatedAtColumn,
  ActionsColumn,
} from './ColumnComponents';
import { ColumnProps } from './types';

const columns = ({
  setPreviewDocumentDetails,
  handlePreviewDocumentDrawer,
  onFileSelect,
  navigate,
  deleteDocument,
  fetchDocuments,
  openActivityLog,
}: ColumnProps): DataTableColumn<DocumentDataType>[] => [
  {
    accessor: 'filename',
    title: 'Name',
    width: 350,
    ellipsis: true,
    render: (element: DocumentDataType) => (
      <FilenameColumn
        element={element}
        setPreviewDocumentDetails={setPreviewDocumentDetails}
        handlePreviewDocumentDrawer={handlePreviewDocumentDrawer}
      />
    ),
  },
  {
    title: 'Last Activity',
    accessor: 'Last Activity',
    render: (element: DocumentDataType) => (
      <LastActivityColumn latestSnapshot={element.latest_snapshot ?? {}} />
    ),
  },
  {
    title: 'Snapshots',
    accessor: 'Snapshots',
    render: (document: DocumentDataType) => (
      <SnapshotsColumn document={document} onFileSelect={onFileSelect} />
    ),
  },
  {
    title: 'Created at',
    accessor: 'Created by',
    render: (element: DocumentDataType) => (
      <CreatedAtColumn latestSnapshot={element.latest_snapshot ?? {}} />
    ),
  },
  {
    title: 'Actions',
    accessor: 'actions',
    width: '20%',
    textAlign: 'center',
    render: (element: DocumentDataType) => (
      <ActionsColumn
        document={element}
        navigate={navigate}
        onFileSelect={onFileSelect}
        deleteDocument={deleteDocument}
        fetchDocuments={fetchDocuments}
        openActivityLog={openActivityLog}
      />
    ),
  },
];

export default columns;
