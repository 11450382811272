const fixedPosition = 'fixed' as const;

export default {
  offsetFromHeader: {
    inner: {
      top: 'var(--app-shell-header-height)',
      height: 'calc(100vh - var(--app-shell-header-height))',
      position: fixedPosition,
    },
    content: {
      height: '100%',
    },
    body: {
      height: '100%',
      padding: 0,
    },
  },
};
