import React from 'react';
import { Outlet } from 'react-router-dom';

import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AuthRoute from 'Components/auth-route/AuthRoute';
import NavBar from 'Components/nav-bar/Navbar';
import SideBar from 'Components/side-bar/SideBar';

import classes from './DefaultLayout.module.scss';

const Dashboard: React.FC = () => {
  const [desktopOpened, handleDesktopOpened] = useDisclosure(true);

  return (
    <AuthRoute>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 220,
          breakpoint: 'sm',
          collapsed: { desktop: !desktopOpened },
        }}
        padding="md"
      >
        <NavBar
          handleDesktopOpened={handleDesktopOpened}
          desktopOpened={desktopOpened}
        />
        <SideBar />
        <AppShell.Main className={classes.mainBody}>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </AuthRoute>
  );
};

export default Dashboard;
