import { Flex, Button, Text, Popover, Tooltip } from '@mantine/core';
import playIcon from 'Assets/play.svg';

type DisplayRetriggerActionProps = {
  open: () => void;
  reTriggerRule: () => void;
  openStatusPrompt: boolean;
  closeConfirmRetriggerModal: () => void;
};

const DisplayRetriggerAction: React.FC<DisplayRetriggerActionProps> = ({
  open,
  reTriggerRule,
  openStatusPrompt,
  closeConfirmRetriggerModal,
}) => {
  return (
    <Popover
      width={300}
      withArrow
      shadow="md"
      opened={openStatusPrompt}
      position="left"
      keepMounted={true}
    >
      <Popover.Target>
        <Tooltip label="Re-trigger rule">
          <img
            src={playIcon}
            onClick={open}
            width="20px"
            height="20px"
            style={{ cursor: 'pointer' }}
          />
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Text ta="center" size="sm">
          This will re-evaluates the rule and update the status. <br />
          Are you sure you want to re-trigger the rule?
        </Text>
        <Flex justify="center" mt="sm">
          <Button onClick={reTriggerRule} size="compact-sm">
            Yes
          </Button>
          <Button
            ml="xs"
            variant="transparent"
            onClick={closeConfirmRetriggerModal}
            size="compact-sm"
          >
            No
          </Button>
        </Flex>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DisplayRetriggerAction;
