import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Badge,
  Button,
  Card,
  Container,
  Group,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core';
import {
  IconArrowsTransferUp,
  IconFeather,
  IconPlus,
} from '@tabler/icons-react';
import BatchService from 'Api/batchService';
import { BatchType } from 'Types/batchTypes';
import { transformDateString } from 'Utils/transform';

import classes from './Batches.module.scss';
import AddBatchDrawer from './components/AddBatchDrawer';

const Batches = () => {
  const [batches, setBatches] = useState<BatchType[] | []>([]);
  const [openAddNewBatchDrawer, setOpenAddNewBatchDrawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBatches();
  }, []);

  const fetchBatches = async () => {
    const { data } = await BatchService.getBatches();
    console.log(data.results);
    setBatches(data.results);
  };

  const batchCards = () => {
    return batches.map((batch, index) => (
      <Card
        key={index}
        shadow="md"
        radius="md"
        className={classes.card}
        padding="md"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/batches/${batch.id}/documents`)}
      >
        <Text fz="md" fw={500} className={classes.cardTitle}>
          Some Name
        </Text>
        <Badge color="gray" size="sm" mt="sm" mb="sm">
          {batch.org.name}
        </Badge>
        <Group align="center" gap="xs">
          <IconFeather size={16} />
          <Text fz="sm" c="dimmed">
            {batch.created_by.username}
          </Text>
        </Group>
        <Group align="center" gap="xs">
          <IconArrowsTransferUp size={16} />
          <Text fz="sm" c="dimmed">
            {transformDateString(batch.updated_at)}
          </Text>
        </Group>
      </Card>
    ));
  };

  const handleNewBatch = () => {
    setOpenAddNewBatchDrawer(true);
  };

  return (
    <Container fluid pl={0}>
      <Group mb="md" align="center">
        <Title order={3}>Batch</Title>
        <Button onClick={handleNewBatch} variant="filled" size="compact-sm">
          <IconPlus size={20} /> Add Batch
        </Button>
      </Group>
      <SimpleGrid cols={{ base: 1, md: 4 }} spacing="xl" mt={10}>
        {batchCards()}
      </SimpleGrid>
      <AddBatchDrawer
        openAddBatchDrawer={openAddNewBatchDrawer}
        setOpenAddBatchDrawer={setOpenAddNewBatchDrawer}
      />
    </Container>
  );
};

export default Batches;
