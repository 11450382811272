import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RootState } from 'Src/redux/store';

type AuthRouteProps = {
  children: ReactNode;
};

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default AuthRoute;
