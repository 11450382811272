import { useDisclosure } from '@mantine/hooks';

type UseLoadingReturnType = [
  boolean,
  { start: () => void; stop: () => void; toggle: () => void },
];

const useLoading = (initialState: boolean): UseLoadingReturnType => {
  const [loading, handlers] = useDisclosure(initialState);
  return [
    loading,
    {
      start: handlers.open,
      stop: handlers.close,
      toggle: handlers.toggle,
    },
  ];
};

export default useLoading;
