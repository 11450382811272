import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Group,
  Text,
  Card,
  LoadingOverlay,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CommentThreadsService } from 'Api/commentThreadsService';
import RuleService from 'Api/ruleService';
import IconDocument from 'Components/icons/IconDocument';
import { CommentStatus } from 'Constants/comments';
import { RuleEvalStatus } from 'Constants/index';
import { RootState } from 'Src/redux/store';
import { CommentType } from 'Types/commentTypes';
import { RuleEvalType, SelectStatusProps } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
} from 'Utils/notifications';

import Details from './Details';
import Header from './Header';

type RuleEvalCardProps = {
  ruleEval: RuleEvalType;
  snapshotId?: number;
  commentThread: CommentType;
  refreshAssessmentDetail?: () => void;
  viewMode?: boolean;
  isHighlighted?: boolean;
  refreshComments?: (snapshotId: number) => Promise<void>;
};

const RuleUnavailable = () => {
  return (
    <Card padding="0" withBorder={false}>
      <Group>
        <IconDocument color={'gray'} />
        <Text size="sm">Rule Unavailable</Text>
      </Group>
      <Divider mt="md" />
    </Card>
  );
};

const RuleEvalCard: React.FC<RuleEvalCardProps> = ({
  commentThread,
  ruleEval,
  refreshAssessmentDetail,
  snapshotId,
  viewMode = false,
  isHighlighted = false,
  refreshComments,
}) => {
  const [ruleEvalLoading, setRuleEvalLoading] = useState<boolean>(false);
  const [
    openStatusPrompt,
    { close: closeConfirmRetriggerModal, open: confirmRetriggerRule },
  ] = useDisclosure(false);
  const [cardOpened, { toggle: toggleCard }] = useDisclosure(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const user = useSelector((state: RootState) => state.auth.user);
  const latestComment =
    Array.isArray(commentThread?.discussion?.latest_posts) &&
    commentThread.discussion.latest_posts.length > 0
      ? commentThread.discussion.latest_posts[0]?.content
      : null;

  const onSelectStatus = async ({
    status,
    newComment,
    onCallback,
  }: SelectStatusProps) => {
    if (!ruleEval || !status) return;
    if (ruleEval.toString() === status) return;
    setRuleEvalLoading(true);
    try {
      if (newComment) {
        const commentData = {
          status: 'open',
          discussion: {
            title: `${ruleEval.rule.title} is set to ${RuleEvalStatus[status as keyof typeof RuleEvalStatus]} by ${user?.username}`,
            posts: [
              {
                content: newComment,
              },
            ],
          },
          document_snapshot_id: snapshotId,
          rule_eval_id: ruleEval.id,
          annotations: [],
        };
        /*
         QUESTION : What if the comment thread is resolved? and we are adding a new comment?
            - Should we create a new comment thread?
            - Should we add the comment to the existing comment thread and mark it as open?
          -- Currently, we are updating the existing comment thread and adding the new comment to it.
        */
        if (commentThread) {
          if (commentThread.status === CommentStatus.RESOLVED) {
            await CommentThreadsService.updateCommentThreads(commentThread.id, {
              status: CommentStatus.OPEN,
            });
          }
          await CommentThreadsService.addPost(commentThread.id, {
            content: newComment,
          });
        } else await CommentThreadsService.createCommentThreads(commentData);
        if (snapshotId) await refreshComments?.(snapshotId);
        if (onCallback) onCallback();
      }
      const { data } = await RuleService.updateRuleEvalStatus(
        ruleEval.id,
        parseInt(status)
      );
      if (data) {
        showInfoNotification(
          'Rule Updated',
          'Rule evaluation status updated successfully'
        );
        refreshAssessmentDetail?.();
      } else {
        throw new Error('Error while updating rule evaluation status');
      }
    } catch (e) {
      showErrorNotification(
        'Something went wrong while update rule evaluation status'
      );
    }
    setRuleEvalLoading(false);
  };

  const reTriggerRule = async () => {
    closeConfirmRetriggerModal();
    if (!ruleEval) return;
    setRuleEvalLoading(true);
    const { data } = await RuleService.triggerRuleEvaluation(
      ruleEval?.id,
      true
    );
    if (data) {
      refreshAssessmentDetail?.();
    }
    setRuleEvalLoading(false);
  };

  const handleNavigateToComment = () => {
    if (commentThread) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('commentId');
      searchParams.delete('ruleEvalId');

      searchParams.set('commentId', commentThread.id.toString());
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  return (
    <Box key={ruleEval?.id} pos="relative">
      <LoadingOverlay
        visible={ruleEvalLoading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
        loaderProps={{ color: 'pink', type: 'bars' }}
      />
      {ruleEval ? (
        <Card
          padding="0"
          withBorder={false}
          style={{
            backgroundColor: isHighlighted
              ? theme.colors.yellow[1]
              : 'transparent',
          }}
        >
          <Header
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            toggleCard={toggleCard}
            cardOpened={cardOpened}
            viewMode={viewMode}
          />
          <Details
            cardOpened={cardOpened}
            currentRuleEval={ruleEval}
            onSelectStatus={onSelectStatus}
            confirmRetriggerRule={confirmRetriggerRule}
            reTriggerRule={reTriggerRule}
            openStatusPrompt={openStatusPrompt}
            closeConfirmRetriggerModal={closeConfirmRetriggerModal}
            latestComment={latestComment}
            viewMode={viewMode}
            handleNavigateToComment={handleNavigateToComment}
          />
          <Divider mt="sm" />
        </Card>
      ) : (
        <RuleUnavailable />
      )}
    </Box>
  );
};

export default RuleEvalCard;
