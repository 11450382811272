import { debounce } from 'lodash';
import React, { SyntheticEvent, useState, useEffect, useMemo } from 'react';

import {
  Flex,
  Input,
  Box,
  ActionIcon,
  Text,
  Group,
  CloseButton,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch, IconFilter, IconArrowsSort } from '@tabler/icons-react';
import { FiltersType } from 'Types/commonTypes';
import { RuleConfig, RuleResponseType } from 'Types/ruleTypes';

import FilterContainer from './FilterContainer';

type PropsTypes = {
  onRuleSelect: (config: RuleConfig) => void;
  noOfSelectedRules: number;
  rules: RuleResponseType;
  fetchRules: (filters?: FiltersType) => void;
  searchRule: (text: string) => void;
  defaultFilters?: FiltersType;
  loading?: boolean;
};

const SearchWithFilters: React.FC<PropsTypes> = ({
  onRuleSelect,
  rules,
  fetchRules,
  searchRule,
  noOfSelectedRules,
  defaultFilters,
  loading,
}) => {
  const [filtersOpened, handleFiltersOpened] = useDisclosure(false);
  const [filters, setFilters] = useState<FiltersType>({});
  const [searchText, setSearchText] = useState<string>('');
  const [selectAll, handleSelectAll] = useDisclosure(true);
  const theme = useMantineTheme();
  const showSelectOrRemoveLabel = useMemo(() => {
    const activeRules = rules.items.filter((rule) => rule.is_active).length;
    const allAddedRules = rules.items.filter((item) => item.hasRuleEval).length;
    /* Do not show select all if all rules are selected */
    if (activeRules === allAddedRules) return false;

    return activeRules && noOfSelectedRules <= activeRules ? true : false;
  }, [noOfSelectedRules, rules.items]);

  const onSelectAll = () => {
    handleSelectAll.toggle();
    onRuleSelect({ addAll: true });
  };

  const onRemoveAll = () => {
    handleSelectAll.toggle();
    onRuleSelect({ removeAll: true });
  };

  const applyFilters = () => {
    const filtersToApply = { ...defaultFilters, ...filters };
    handleFiltersOpened.close();
    fetchRules(filtersToApply);
  };

  const onClearFilters = () => {
    setFilters({});
    fetchRules();
    handleFiltersOpened.close();
  };

  const debouncedSearch = debounce(searchRule, 500);

  const onSearch = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;

    // If search input is empty, clear the search
    if (value.length === 0) {
      return onClearSearch();
    }

    const hasAppliedFilters = Object.keys(filters).length > 0;
    if (hasAppliedFilters) {
      // Clear filters when search is initiated
      setFilters({});
      handleFiltersOpened.close();
    }

    setSearchText(value);
    debouncedSearch(value);
  };

  const onClearSearch = () => {
    setSearchText('');
    /* Reset filters if search text is empty */
    setFilters({ ...defaultFilters });
    applyFilters();
  };

  useEffect(() => {
    if (!defaultFilters) {
      setFilters({});
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box py={8} w={'100%'}>
      {/* Search and other action items */}
      <Flex justify={'space-between'} w={'100%'} align={'center'}>
        <Input
          size="sm"
          placeholder="Search.."
          leftSection={<IconSearch size={18} />}
          w={'85%'}
          onChange={onSearch}
          value={searchText}
          rightSectionPointerEvents="all"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => onClearSearch()}
              style={{ display: searchText ? undefined : 'none' }}
            />
          }
        />
        <ActionIcon
          size={'input-sm'}
          variant={filtersOpened ? 'primary"' : 'default'}
          onClick={() => handleFiltersOpened.toggle()}
        >
          <IconFilter stroke={1} />
        </ActionIcon>
        <ActionIcon size={'input-sm'} variant="default" disabled>
          <IconArrowsSort stroke={1} />
        </ActionIcon>
      </Flex>
      <FilterContainer
        filtersOpened={filtersOpened}
        onClearFilters={onClearFilters}
        filters={filters}
        setFilters={setFilters}
        applyFilters={applyFilters}
        handleFiltersOpened={handleFiltersOpened}
        totalRules={rules.count}
        defaultFilters={{ is_active: true }}
        loading={loading}
      />
      {/* Actions related selecting/de-selcting the rules */}
      <Flex
        mt="sm"
        justify={'space-between'}
        style={{ transition: '0.5s ease-out' }}
      >
        <Flex justify={'flex-start'}>
          {showSelectOrRemoveLabel && (
            <Tooltip
              label="Select all active rules"
              disabled={!selectAll}
              position="top"
              withArrow
            >
              <Text
                size="xs"
                fw={700}
                c={selectAll ? theme.colors.cyan[7] : theme.colors.red[6]}
                style={{ cursor: 'pointer' }}
                onClick={() => (selectAll ? onSelectAll() : onRemoveAll())}
              >
                {selectAll ? 'Select all' : 'Remove all'}
              </Text>
            </Tooltip>
          )}
        </Flex>
        <Flex justify={'flex-end'} gap="xs">
          <Group>
            <Text size="xs">{`${noOfSelectedRules} / ${rules.count} selected`}</Text>
          </Group>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SearchWithFilters;
