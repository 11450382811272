import { useSelector } from 'react-redux';

import { Text } from '@mantine/core';
import { RootState } from 'Src/redux/store';
import { MessageTypes } from 'Types/chatTypes';

import classes from './ChatMessage.module.scss';

type ChatMessageProps = MessageTypes & {
  timestamp?: Date;
};

const ChatMessage: React.FC<ChatMessageProps> = ({ sender, data }) => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    user && (
      <div className={`${classes.chatMessage} ${classes[sender]}`}>
        <Text size="sm">{data}</Text>
      </div>
    )
  );
};

export default ChatMessage;
