import React, { useEffect, useState } from 'react';

import {
  TextInput,
  Button,
  Drawer,
  Flex,
  Title,
  Switch,
  Textarea,
  Text,
  Stack,
  Group,
  Box,
  Select,
  Grid,
} from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons-react';
// import PolicyService from 'Api/policyService';
import RuleService from 'Api/ruleService';
import ExpressionInput from 'Components/expression-input/ExpressionInput';
import { RuleEvalStatus } from 'Constants/index';
import { RuleType } from 'Types/ruleTypes';
import {
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
} from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

type AddRuleProps = {
  openAddRuleDrawer: boolean;
  setOpenAddRuleDrawer: (open: boolean) => void;
  setRuleToView: (rule: any) => void;
  ruleToView: RuleType | null;
  refreshRules: () => void;
};

const initialFormValues: Partial<RuleType> = {
  title: '',
  expression: { script: '' },
  is_active: false,
};

const AddRuleDrawer: React.FC<AddRuleProps> = ({
  openAddRuleDrawer,
  setOpenAddRuleDrawer,
  ruleToView,
  refreshRules,
}) => {
  const [addFormValues, setAddFormValues] = useState<Partial<RuleType>>({
    ...initialFormValues,
  });

  useEffect(() => {
    if (ruleToView) {
      setAddFormValues(ruleToView);
    } else {
      setAddFormValues(initialFormValues);
    }
  }, [ruleToView]);

  const handleSubmit = async () => {
    try {
      if (ruleToView?.id) {
        const response = await RuleService.updateRules(
          ruleToView.id,
          addFormValues
        );
        if (response && response.data) {
          showInfoNotification(
            'Rule Updated',
            'Rule has been updated successfully'
          );
          setOpenAddRuleDrawer(false);
          refreshRules();
        }
      } else {
        console.log(addFormValues, 'addFormValues');
        const response = await RuleService.createRules({
          ...addFormValues,
        });
        if (response && response.data) {
          showSuccessNotification(
            'Rule Added',
            'Rule has been added successfully'
          );

          setOpenAddRuleDrawer(false);
          setAddFormValues(initialFormValues);
          refreshRules();
        }
      }
    } catch (error: any) {
      showErrorNotification(error.message || 'Something went wrong');
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setAddFormValues({
      ...addFormValues,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Drawer
      opened={openAddRuleDrawer}
      onClose={() => setOpenAddRuleDrawer(false)}
      title={
        <Flex justify="space-between">
          <Group gap="sm">
            <Title
              order={4}
            >{`${ruleToView?.id ? `Update` : `Add New`} Rule`}</Title>
            <Button
              onClick={handleSubmit}
              variant="outline"
              size="compact-sm"
              leftSection={<IconDeviceFloppy size={20} />}
            >
              {ruleToView?.id ? `Save` : `Add`}
            </Button>
          </Group>
        </Flex>
      }
      position="right"
    >
      <form>
        <div>
          <TextInput
            value={addFormValues.title}
            name="title"
            label="Title"
            placeholder="Title"
            required
            onChange={handleChange}
            size="sm"
          />
          <Textarea
            value={addFormValues.description || ''}
            name="description"
            label="Description"
            placeholder="This rules look validates...."
            required
            onChange={handleChange}
            rows={3}
            mt="sm"
          />
          <ExpressionInput
            rows={4}
            mt="sm"
            value={addFormValues.expression?.script}
            name="expression.script"
            label="Expression"
            placeholder="Add expression which evaluates to boolean. for eg: 1+1 = 2"
            required
            onChange={(event) => {
              setAddFormValues({
                ...addFormValues,
                expression: { script: event.target.value, is_valid: true },
              });
            }}
          />
          <Grid>
            <Grid.Col span={9}>
              <TextInput
                mt="sm"
                value={addFormValues.temp_group_name}
                name="temp_group_name"
                label="Tags"
                placeholder="Add tags, to filter later"
                required
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Stack mt="md" gap={1}>
                <Text size="sm">Status</Text>
                <Switch
                  mt={5}
                  size="lg"
                  onLabel="Active"
                  offLabel="Inactive"
                  name="is_active"
                  checked={addFormValues.is_active}
                  onChange={(event) => {
                    setAddFormValues({
                      ...addFormValues,
                      [event.target.name]: event.currentTarget.checked,
                    });
                  }}
                />
              </Stack>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <Select
                mt="sm"
                size="sm"
                name="on_true_eval_status"
                label="On True Eval Status"
                placeholder="Eval status on True"
                onChange={(value) => {
                  if (!value) return;
                  setAddFormValues({
                    ...addFormValues,
                    on_true_eval_status: parseInt(value),
                  });
                }}
                data={Object.keys(RuleEvalStatus).map((status) => {
                  const key = status as keyof typeof RuleEvalStatus;
                  return {
                    value: key,
                    label: RuleEvalStatus[key],
                  };
                })}
                value={addFormValues.on_true_eval_status?.toString()}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                mt="sm"
                size="sm"
                name="on_false_eval_status"
                label="On False Eval Status"
                placeholder="Eval status on false"
                onChange={(value) => {
                  if (!value) return;
                  setAddFormValues({
                    ...addFormValues,
                    on_false_eval_status: parseInt(value),
                  });
                }}
                data={Object.keys(RuleEvalStatus).map((status) => {
                  const key = status as keyof typeof RuleEvalStatus;
                  return {
                    value: key,
                    label: RuleEvalStatus[key],
                  };
                })}
                value={addFormValues.on_false_eval_status?.toString()}
              />
            </Grid.Col>
          </Grid>
          <Select
            mt="sm"
            size="sm"
            name="on_error_eval_status"
            label="On Error Eval Status"
            placeholder="Eval status on error"
            onChange={(value) => {
              if (!value) return;
              setAddFormValues({
                ...addFormValues,
                on_error_eval_status: parseInt(value),
              });
            }}
            data={Object.keys(RuleEvalStatus).map((status) => {
              const key = status as keyof typeof RuleEvalStatus;
              return {
                value: key,
                label: RuleEvalStatus[key],
              };
            })}
            value={addFormValues.on_error_eval_status?.toString()}
          />
        </div>
      </form>
      {ruleToView?.id && (
        <Box mt="xl">
          <Text c="dimmed" size="sm">
            Last Updated By : {ruleToView.updated_by?.username}
          </Text>
          <Text c="dimmed" size="sm">
            Updated At : {transformDateString(ruleToView.updated_at)}
          </Text>
          <Text c="dimmed" size="sm">
            Created By : {ruleToView.created_by?.username}
          </Text>
          <Text c="dimmed" size="sm">
            Created At : {transformDateString(ruleToView.created_at)}
          </Text>
        </Box>
      )}
    </Drawer>
  );
};

export default AddRuleDrawer;
