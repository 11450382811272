import { useEffect, useCallback, useRef } from 'react';

import { WebViewerInstance, Core } from '@pdftron/webviewer';
import AnnotationService from 'Api/annotationService';
import { CommentThreadsService } from 'Api/commentThreadsService';
import { Mode } from 'Constants/index';
import { CommentType } from 'Types/commentTypes';
import { ModalCoordinates } from 'Types/commonTypes';
import { SnapshotAnnotationType } from 'Types/docTypes';
import { AnnotationConfigTypes, FileViwerMode } from 'Types/fileViewerTypes';
import { showErrorNotification } from 'Utils/notifications';
import { createInitialsIcon, getInitialsFromNames } from 'Utils/transform';

type PropTypes = {
  instance: WebViewerInstance | null;
  annotationConfig: AnnotationConfigTypes | undefined;
  mode?: FileViwerMode;
  showReplies: (
    threadId: string,
    pageNumber: number,
    coordinate: ModalCoordinates
  ) => void;
  commentsConfig?: {
    mode: string;
    enableComments: boolean;
  };
};

enum AnnotationAction {
  MODIFY = 'modify',
  ADD = 'add',
  DELETE = 'delete',
}

type AnnotationType = Core.Annotations.Annotation & { Id: string };

const useSnapshotAnnotations = ({
  instance,
  annotationConfig,
  mode,
  showReplies,
  commentsConfig,
}: PropTypes) => {
  const {
    displayAnnotations = true,
    documentSnaphostId,
    enableAnnotationEditing = true,
    assessmentId,
  } = annotationConfig || {};
  const { enableComments, mode: CommentsMode } = commentsConfig || {};
  const initialsIconCache = useRef<Record<string, string>>({});

  const generateInitialsIcon = useCallback((initials: string) => {
    if (initialsIconCache.current[initials]) {
      return initialsIconCache.current[initials];
    }
    const initialsIconSvg = createInitialsIcon(initials);
    initialsIconCache.current[initials] = initialsIconSvg;
    return initialsIconSvg;
  }, []);

  const createStampAnnoation = (
    annotation: AnnotationType,
    instance: WebViewerInstance,
    thredId: number,
    user: any
  ) => {
    const { annotationManager, Annotations } = instance.Core;
    const stampAnnotation = new Annotations.StampAnnotation();

    stampAnnotation.PageNumber = annotation.PageNumber;

    // Calculate the position for the stamp annotation
    const annotationWidth = annotation.Width;
    stampAnnotation.X = annotation.X + annotationWidth - 10;
    stampAnnotation.Y = annotation.Y - 35;
    stampAnnotation.Width = 40;
    stampAnnotation.Height = 40;
    stampAnnotation.Color = new Annotations.Color(205, 48, 125);

    let initials = 'DR';
    if (user?.first_name && user?.last_name) {
      initials = getInitialsFromNames(user.first_name, user.last_name);
    }
    const iconWithInitials = generateInitialsIcon(initials);
    stampAnnotation.setImageData(iconWithInitials, true);

    // Set custom data
    stampAnnotation.setCustomData('commentThreadId', thredId?.toString());

    // Add the annotation to the document
    annotationManager.addAnnotation(stampAnnotation, { imported: true });
    annotationManager.redrawAnnotation(stampAnnotation);
  };

  useEffect(() => {
    if (!instance || !documentSnaphostId) return;
    const { annotationManager, Annotations } = instance.Core;
    const annotationIds = new Map<string, number>();

    if (!enableComments) {
      const existingStamps = annotationManager
        .getAnnotationsList()
        .filter(
          (a: AnnotationType) =>
            a instanceof Annotations.StampAnnotation &&
            a.getCustomData('commentThreadId')
        );
      existingStamps.forEach((stamp) => {
        annotationManager.deleteAnnotation(stamp, { imported: true });
      });
    }

    const renderAnnotations = async () => {
      try {
        const response = await AnnotationService.getAnnotations({
          filters: { document_snapshot: documentSnaphostId },
        });
        const { results = [] } = response.data || {};

        // Fetch comment threads
        const commentsRes = await CommentThreadsService.getCommentThreads({
          document_snapshot: documentSnaphostId,
        });
        const commentThreads = commentsRes?.data?.results || [];
        const idToCommentMap = new Map(
          commentThreads.map((thread: CommentType) => [thread.id, thread])
        );

        results.forEach(async (item: SnapshotAnnotationType) => {
          const annotations = await annotationManager.importAnnotations(
            item.annotation_xml
          );

          annotations.forEach((annotation: AnnotationType) => {
            const annotationId = annotation.Id;
            const commentThreadId = item.comment_thread;

            if (enableComments && commentThreadId) {
              const comment = idToCommentMap.get(
                commentThreadId
              ) as CommentType;
              createStampAnnoation(
                annotation,
                instance,
                commentThreadId,
                comment?.created_by
              );
            }

            if (annotationId) {
              annotationIds.set(annotationId, item.id);
              annotationManager.redrawAnnotation(annotation);
            }
          });
        });
      } catch (e) {
        showErrorNotification('Error fetching annotations');
      }
    };

    const onAnnotationSelected = (annotations: AnnotationType[]) => {
      if (annotations.length > 0) {
        const selectedAnnotation = annotations[0];

        if (
          enableComments &&
          selectedAnnotation instanceof Annotations.StampAnnotation
        ) {
          const commentThreadId =
            selectedAnnotation.getCustomData('commentThreadId');
          if (commentThreadId && commentThreadId !== '') {
            instance.UI.disableElements(['annotationPopup']);
            selectedAnnotation.NoResize = true;
            selectedAnnotation.disableRotationControl();
            selectedAnnotation.NoRotate = true;
            const pageNumber = selectedAnnotation.getPageNumber();
            const rect = selectedAnnotation.getRect();
            const { x1, y1 } = rect;

            const doc = instance.Core.documentViewer.getDocument();
            const viewer = document.querySelector('.pdf-viewer-container');

            if (viewer) {
              const screenCoordinates = doc.getPDFCoordinates(
                pageNumber,
                x1,
                y1
              );

              const viewerRect = viewer.getBoundingClientRect();
              const x = Math.max(
                0,
                Math.min(screenCoordinates.x, viewerRect.width - 300)
              );
              const y = Math.max(
                0,
                Math.min(screenCoordinates.y, viewerRect.height - 200)
              );

              showReplies(commentThreadId, pageNumber, { x, y });
            }
          }
        }
      }
    };

    const onAnnotationChange = async (
      annotations: Core.Annotations.Annotation[],
      action: AnnotationAction,
      { imported }: { imported: boolean }
    ) => {
      if (imported) return;

      /*
        Note:
        - To handle CRUD operations at the document level, we are currently using `exportAnnotations`. 
          This approach provides flexibility in managing individual annotations and offers more options. 
          For more details, refer to: https://docs.apryse.com/api/web/Core.AnnotationManager.html#exportAnnotations

        - If batch operations and real-time updates are required, `exportAnnotationCommand` is a better choice. 
          However, this method is based on change events (add, modify) and does not support individual operations. 
          For more details, refer to: https://docs.apryse.com/api/web/Core.AnnotationManager.html#exportAnnotationCommand
      */
      try {
        if ([AnnotationAction.MODIFY, AnnotationAction.ADD].includes(action)) {
          for (const annotation of annotations) {
            const commentData = annotation.getCustomData('comment');
            const commentInfo = commentData
              ? JSON.parse(annotation.getCustomData('comment'))
              : null;

            if (commentData) {
              annotation.deleteCustomData('comment');
            }
            const identifier: string = annotation.Id;
            const xfdfString = await annotationManager.exportAnnotations({
              annotList: [annotation],
            });
            let commentThreadPostId;

            if (commentInfo) {
              const commentText = commentInfo.text;
              const requestData = {
                status: 'open',
                discussion: {
                  title: '',
                  posts: [
                    {
                      content: commentText,
                    },
                  ],
                },
                document_snapshot_id: documentSnaphostId,
                annotations: [{ annotation_xml: xfdfString }],
              };
              if (enableComments && CommentsMode === Mode.EDIT && commentInfo) {
                const commentResp =
                  await CommentThreadsService.createCommentThreads(requestData);

                if (commentResp?.data) {
                  commentThreadPostId = commentResp?.data?.id;

                  createStampAnnoation(
                    annotation,
                    instance,
                    commentThreadPostId,
                    commentResp?.data?.created_by
                  );
                }
              }
            }

            const requestData = {
              annotation_xml: xfdfString,
              document_snapshot: documentSnaphostId,
              assessment: assessmentId,
              rule_eval: null,
              rule_execution: null,
              comment_thread: commentThreadPostId ?? null,
            };
            if (action === AnnotationAction.ADD) {
              const response =
                await AnnotationService.createAnnotations(requestData);
              annotationIds.set(identifier, response.data.id);
            } else {
              const annotationId = annotationIds.get(identifier);
              if (annotationId)
                await AnnotationService.updateAnnotations(
                  annotationId,
                  requestData
                );
            }
          }
        } else if (action === AnnotationAction.DELETE) {
          for (const annotation of annotations) {
            const identifier: string = annotation.Id;
            const annotationId = annotationIds.get(identifier);
            if (annotationId) {
              await AnnotationService.deleteAnnotations(annotationId);
            }
            annotationIds.delete(identifier);
          }
        }
      } catch (error) {
        showErrorNotification('Error updating/creating annotations');
      }
    };

    if (mode === FileViwerMode.VIEW) {
      renderAnnotations();
      annotationManager.enableReadOnlyMode();
    } else {
      if (displayAnnotations) {
        renderAnnotations();
      }
      if (enableAnnotationEditing) {
        annotationManager.addEventListener(
          'annotationChanged',
          onAnnotationChange
        );
        annotationManager.addEventListener(
          'annotationSelected',
          onAnnotationSelected
        );
      }
    }

    return () => {
      if (instance) {
        instance.Core.annotationManager.removeEventListener(
          'annotationChanged',
          onAnnotationChange
        );
        instance.Core.annotationManager.removeEventListener(
          'annotationSelected',
          onAnnotationSelected
        );
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [instance, commentsConfig?.mode, commentsConfig?.enableComments]);
};

export default useSnapshotAnnotations;
