import React from 'react';

import {
  Drawer as MantineDrawer,
  DrawerProps,
  DrawerBodyProps,
  DrawerCloseButtonProps,
  DrawerOverlayProps,
  DrawerTitleProps,
  CSSProperties,
  ModalBaseStylesNames,
} from '@mantine/core';

import customStyles from './styles';

interface CustomDrawerProps extends DrawerProps {
  offsetFromHeader?: boolean;
  styles?: Partial<Record<ModalBaseStylesNames, CSSProperties>>;
}

const Drawer: React.FC<CustomDrawerProps> & {
  Body: React.FC<DrawerBodyProps>;
  CloseButton: React.FC<DrawerCloseButtonProps>;
  Overlay: React.FC<DrawerOverlayProps>;
  Title: React.FC<DrawerTitleProps>;
} = ({ offsetFromHeader = false, styles = {}, children, ...props }) => {
  const drawerStyles = {
    ...styles,
    ...(offsetFromHeader && customStyles.offsetFromHeader),
  };

  return (
    <MantineDrawer styles={drawerStyles} {...props}>
      {children}
    </MantineDrawer>
  );
};

// Subcomponents
Drawer.Body = MantineDrawer.Body;
Drawer.CloseButton = MantineDrawer.CloseButton;
Drawer.Overlay = MantineDrawer.Overlay;
Drawer.Title = MantineDrawer.Title;

export default Drawer;
