import { LoadingOverlay as MantineLoadingOverlay } from '@mantine/core';

type TLoadingOverlay = {
  visible: boolean;
  type?: 'string';
};

const LoadingOverlay: React.FC<TLoadingOverlay> = ({
  visible,
  type = 'bars',
}) => {
  return (
    <MantineLoadingOverlay
      visible={visible}
      zIndex={1000}
      overlayProps={{ radius: 'sm', blur: 2 }}
      loaderProps={{ color: 'pink', type, size: 20 }}
    />
  );
};

export default LoadingOverlay;
