import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ActionIcon,
  AppShell,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Menu,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDotsVertical, IconNotebook, IconShare } from '@tabler/icons-react';
import FileViewer from 'Components/file-viewer/FileViewer';
import IRNavbar from 'Components/nav-bar/IRNavbar';
import { LoadingStatus, Mode } from 'Constants/index';
import 'Slices/activeAssessmentSlice';
import { fetchActiveAssessment } from 'Slices/activeAssessmentSlice';
import { fetchActiveDocument } from 'Slices/activeDocumentSlice';
import { AppDispatch, RootState } from 'Src/redux/store';
import { DocumentSnapshotType } from 'Types/docTypes';
import { FileViwerMode } from 'Types/fileViewerTypes';
import { TextCoords } from 'Types/fileViewerTypes';

import NotFound from '../not-found/NotFound';
import classes from './Assessment.module.scss';
import BatchList from './components/BatchLists';
import CompareSnapshotModal from './components/CompareSnapshotsModal';
import SelectSnapshotVersions from './components/SelectSnapshotVersions';
import TabsContainer from './components/TabsContainer';

const MoreMenu = () => {
  return (
    <Menu width={200} shadow="md">
      <Menu.Target>
        <ActionIcon variant="transparent">
          <IconDotsVertical size={20} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item leftSection={<IconShare size={15} />}>Share</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const Assessment: React.FC = () => {
  const { batchId, docId } = useParams<{
    batchId: string;
    docId: string;
  }>();
  const dispatch = useDispatch<AppDispatch>();
  const assessmentData = useSelector(
    (state: RootState) => state.activeAssessment.data
  );
  const documentData = useSelector(
    (state: RootState) => state.activeDocument.data
  );
  const documentDataLoading = useSelector(
    (state: RootState) => state.activeDocument.loading
  );
  const snapshotVersions = documentData?.snapshots || [];

  console.log(assessmentData, 'assessmentData');
  const navigate = useNavigate();
  const [textCoords, setTextCoords] = useState<TextCoords | null>(null);
  const [showCompareModal, setShowCompareModal] = useDisclosure(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState<
    DocumentSnapshotType | undefined
  >(undefined);
  const [showComments, toggleComments] = useState<boolean>(false);

  useEffect(() => {
    console.log(documentData, 'documentData');
    if (documentData && documentData.favorite_snapshot && docId && batchId) {
      if (
        documentData.favorite_snapshot?.id ||
        documentData.latest_snapshot?.id
      ) {
        dispatch(
          fetchActiveAssessment({
            docId,
            batchId,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentData]);

  useEffect(() => {
    if (!batchId || !docId) return;
    dispatch(fetchActiveDocument(parseInt(docId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId, docId]);

  useEffect(() => {
    if (selectedSnapshot) setShowCompareModal.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSnapshot]);

  const onSnapshotPreviewClose = () => {
    setSelectedSnapshot(undefined);
    setShowCompareModal.close();
  };

  return (
    <AppShell
      header={{ height: 74 }}
      navbar={{
        width: 230,
        breakpoint: 'lg',
      }}
    >
      <AppShell.Header>
        <IRNavbar />
      </AppShell.Header>
      {/* {loading && (
        <AppShell.Main className={classes.mainBody} pos="relative">
          <LoadingOverlay
            zIndex={1}
            visible={loading}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'pink', type: 'bars' }}
          />
        </AppShell.Main>
      )} */}
      {documentData && documentData.favorite_snapshot ? (
        <>
          <AppShell.Navbar>
            <AppShell.Section grow>
              <BatchList documentData={documentData} />
            </AppShell.Section>
            <AppShell.Section></AppShell.Section>
          </AppShell.Navbar>
          <AppShell.Main className={classes.mainBody}>
            <Grid gutter={0}>
              <Grid.Col className={classes.compareBox} span={6}>
                <Box py="xs" px="xs">
                  <Flex align="center" justify="space-between">
                    <Group gap="xs" wrap={'nowrap'} w={'40%'}>
                      <IconNotebook
                        size={20}
                        color="#757575"
                        style={{ flexShrink: 0 }}
                      />
                      <Tooltip label={documentData?.filename}>
                        <Text size="16px" c="#757575" truncate="end">
                          {documentData?.filename}
                        </Text>
                      </Tooltip>
                    </Group>
                    <Group gap="xs">
                      {snapshotVersions?.length > 1 && (
                        <SelectSnapshotVersions
                          selectedSnapshot={selectedSnapshot?.id?.toString()}
                          snapshotVersions={documentData.snapshots || []}
                          setSnapshotVersions={() => {}}
                          setSelectedSnapshot={setSelectedSnapshot}
                          primarySnapshot={documentData?.favorite_snapshot}
                          selectViewType="menu"
                        />
                      )}
                      <MoreMenu />
                    </Group>
                  </Flex>
                </Box>
                <FileViewer
                  fileUrl={documentData?.favorite_snapshot?.file || ''}
                  textCoords={textCoords}
                  mode={FileViwerMode.EDIT}
                  annotationConfig={{
                    documentSnaphostId: documentData?.favorite_snapshot?.id,
                    assessmentId: assessmentData?.id,
                  }}
                  {...(showComments && {
                    commentsConfig: {
                      enableComments: true,
                      mode: Mode.EDIT,
                    },
                  })}
                />
              </Grid.Col>
              <Grid.Col className={classes.docInfoBox} span={6}>
                <TabsContainer
                  setTextCoords={setTextCoords}
                  toggleComments={toggleComments}
                />
              </Grid.Col>
            </Grid>
          </AppShell.Main>
        </>
      ) : documentDataLoading === LoadingStatus.LOADING ? null : (
        <NotFound
          title="Not Found"
          description={`Page you are trying to open does not exist. You may have mistyped the url, or the Snapshot document doesn't exists. If you think this is an error contact support.`}
          action={
            <Button onClick={() => navigate('/documents')}>
              Go Back to Documents
            </Button>
          }
        />
      )}
      {showCompareModal && documentData && selectedSnapshot && (
        <CompareSnapshotModal
          opened={showCompareModal}
          onClose={onSnapshotPreviewClose}
          snapshotForCompare={selectedSnapshot}
          primarySnapshot={documentData?.favorite_snapshot}
          setSelectedSnapshot={setSelectedSnapshot}
          refreshAssessment={() => {}}
          setSnapshotVersions={() => {}}
        />
      )}
    </AppShell>
  );
};

export default Assessment;
