import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box } from '@mantine/core';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import MessageIcon from 'Assets/message.svg';
import { Mode } from 'Constants/index';
import useSnapshotAnnotations from 'Src/hooks/useSnapshotAnnotations';
import useTextHighlight from 'Src/hooks/useTextHighlight';
import { ModalCoordinates } from 'Types/commonTypes';
import { FileViwerMode, AnnotationConfigTypes } from 'Types/fileViewerTypes';
import { TextCoords } from 'Types/fileViewerTypes';
import { showErrorNotification } from 'Utils/notifications';

import MessagePopup from './MessagePopup';
import { getViewerInitConfig, ViewerFitMode } from './fileViewerHelper';

interface PdfViewerProps {
  fileUrl: string;
  enabledControls?: string[];
  disabledControls?: string[];
  onInstanceReady?: (instance: WebViewerInstance) => void;
  searchText?: string | null;
  textCoords?: TextCoords | null;
  mode?: FileViwerMode;
  height?: string;
  annotationConfig?: AnnotationConfigTypes;
  commentsConfig?: {
    mode: string;
    enableComments: boolean;
  };
}

const FileViewer: React.FC<PdfViewerProps> = ({
  fileUrl,
  onInstanceReady,
  textCoords,
  mode = FileViwerMode.VIEW,
  height = '100vh',
  annotationConfig,
  commentsConfig = {
    mode: Mode.VIEW,
    enableComments: false,
  },
}) => {
  const location = useLocation();
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [webViewerInstance, setWebViewerInstance] =
    useState<WebViewerInstance | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCoordinates, setModalCoordinates] =
    useState<ModalCoordinates | null>(null);
  const [activeCommentThreadId, setActiveCommentThreadId] = useState<
    string | null
  >(null);

  /* Crud for annotations based on `annotationConfig` */
  useSnapshotAnnotations({
    instance: webViewerInstance,
    annotationConfig,
    mode,
    showReplies: (
      id: string,
      pageNumber: number,
      coordinates: ModalCoordinates
    ) => {
      setModalCoordinates({
        x: coordinates.x,
        y: coordinates.y,
        pageNumber,
      });
      setModalOpen(true);
      setActiveCommentThreadId(id);
    },
    commentsConfig,
  });

  // Highlight text in the document based on provided coordinates
  useTextHighlight(webViewerInstance, textCoords);

  useEffect(() => {
    if (!webViewerInstance) return;

    if (commentsConfig.enableComments && commentsConfig.mode === Mode.EDIT) {
      webViewerInstance.UI.enableElements(['CustomMessageIcon']);
    } else {
      webViewerInstance.UI.disableElements(['CustomMessageIcon']);
    }
  }, [commentsConfig.mode, commentsConfig.enableComments, webViewerInstance]);

  const initializeInstance = (instance: WebViewerInstance) => {
    const { UI } = instance;
    const { documentViewer } = instance.Core;

    UI.addEventListener(instance.UI.Events.VIEWER_LOADED, () => {
      UI.setFitMode(ViewerFitMode.FITWIDTH);
    });

    UI.textPopup.add([
      {
        type: 'actionButton',
        dataElement: 'CustomMessageIcon',
        img: MessageIcon,
        onClick: async () => {
          const selection = documentViewer.getSelectedTextQuads();
          const viewer = document.querySelector('.pdf-viewer-container');
          if (selection && viewer) {
            const pageNumber = parseInt(Object.keys(selection)[0], 10);
            const firstQuad = selection[pageNumber][0];
            const { x1, y1 } = firstQuad;

            // Convert page coordinates to screen coordinates
            const pdfCoordinates = { x: x1, y: y1 };
            const Viewerdocument = documentViewer.getDocument();
            const screenCoordinates = Viewerdocument.getPDFCoordinates(
              pageNumber,
              pdfCoordinates.x,
              pdfCoordinates.y
            );

            const viewerRect = viewer.getBoundingClientRect();
            const x = Math.max(
              0,
              Math.min(screenCoordinates.x, viewerRect.width - 300)
            );
            const y = Math.max(
              0,
              Math.min(screenCoordinates.y, viewerRect.height - 200)
            );

            setModalCoordinates({
              x,
              y,
              pageNumber,
            });
            setModalOpen(true);
          }
        },
      },
    ]);

    documentViewer.addEventListener('documentLoaded', () => {
      // call methods relating to the loaded document
      setWebViewerInstance(instance);
      if (onInstanceReady) {
        // we can define custom logic on callback
        onInstanceReady(instance);
      }
    });
    instance.UI.loadDocument(fileUrl);
  };

  const createWebViewerInstance = async () => {
    if (!viewerRef.current) return;
    try {
      const instance = await WebViewer(
        {
          path: '/webviewer',
          licenseKey: import.meta.env.VITE_APRYSE_LICENSE_KEY,
          disabledElements: getViewerInitConfig(mode),
          fullAPI: true,
        },
        viewerRef.current
      );
      initializeInstance(instance);
    } catch (e) {
      console.log(e);
      showErrorNotification(
        'Unable to load the document viewer. Please reload the page or try again later.'
      );
    }
  };

  // Creates or reuse existing WebViewer instance
  useEffect(() => {
    if (webViewerInstance) {
      initializeInstance(webViewerInstance);
      return;
    }
    if (viewerRef.current) {
      createWebViewerInstance();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (fileUrl && webViewerInstance) {
      webViewerInstance.UI.loadDocument(fileUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fileUrl]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const annotationId = searchParams.get('annotationId');
    webViewerInstance?.Core?.annotationManager.deselectAllAnnotations();

    if (annotationId && webViewerInstance) {
      const { annotationManager } = webViewerInstance.Core;
      const annotation = annotationManager.getAnnotationById(annotationId);

      if (annotation) {
        console.log('Annotation found:', annotation);
        annotationManager.selectAnnotation(annotation);
        webViewerInstance.Core.documentViewer.displayPageLocation(
          annotation.getPageNumber(),
          0,
          0,
          true
        );
      } else {
        console.log(`Annotation with id ${annotationId} not found`);
      }
    } else {
      console.log('No annotationId or webViewerInstance not ready');
    }
  }, [location.search, webViewerInstance]);

  const onReply = (message: string) => {
    if (!webViewerInstance) return;
    const { documentViewer, annotationManager, Annotations } =
      webViewerInstance.Core;

    const selection = documentViewer.getSelectedTextQuads();
    if (selection) {
      const pageNumber = parseInt(Object.keys(selection)[0], 10);
      const quads = selection[pageNumber];

      // Create a new TextHighlightAnnotation
      const highlight = new Annotations.TextHighlightAnnotation();
      highlight.PageNumber = pageNumber;
      highlight.Quads = quads.map((quad: Record<string, string>) => ({
        x1: quad.x1,
        y1: quad.y1,
        x2: quad.x2,
        y2: quad.y2,
        x3: quad.x3,
        y3: quad.y3,
        x4: quad.x4,
        y4: quad.y4,
      }));
      highlight.StrokeColor = new Annotations.Color(255, 255, 0);
      highlight.setCustomData(
        'comment',
        JSON.stringify({ text: message, id: null })
      );
      // Add the annotation to the document
      annotationManager.addAnnotation(highlight);
      annotationManager.redrawAnnotation(highlight);
    }
  };

  return (
    <Box className="pdf-viewer-container" h={height}>
      <div style={{ height }} ref={viewerRef}></div>
      {commentsConfig.enableComments && (
        <MessagePopup
          coordinates={modalCoordinates}
          isOpen={modalOpen}
          onClose={() => {
            webViewerInstance?.Core?.annotationManager.deselectAllAnnotations();
            setModalOpen(false);
            setActiveCommentThreadId(null);
            webViewerInstance?.UI.enableElements(['annotationPopup']);
          }}
          onSubmit={onReply}
          activeCommentThreadId={activeCommentThreadId}
          // viewMode={commentsConfig}
          commentsConfig={commentsConfig}
        />
      )}
    </Box>
  );
};

export default FileViewer;
