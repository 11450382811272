import React from 'react';

import { Box, Text, Checkbox, Group, Spoiler, Tooltip } from '@mantine/core';
import { RuleWithEvalType, RuleConfig } from 'Types/ruleTypes';

const RuleItem: React.FC<{
  item: RuleWithEvalType;
  alreadySelected: boolean;
  onRuleSelect: (rule: RuleConfig) => void;
}> = ({ item, alreadySelected, onRuleSelect }) => (
  <React.Fragment key={item.id}>
    <Group
      wrap="nowrap"
      align="flex-start"
      style={{
        pointerEvents: item?.hasRuleEval ? 'none' : 'inherit',
        opacity: item?.hasRuleEval ? 0.5 : 'inherit',
      }}
    >
      <Tooltip
        label="This rule is inactive"
        disabled={item.is_active}
        position="top"
        withArrow
      >
        <Checkbox
          checked={alreadySelected}
          mt={4}
          disabled={!item.is_active}
          onClick={() => onRuleSelect({ ruleToAdd: item })}
        />
      </Tooltip>
      <Box>
        <Text size="sm" fw={400}>
          {item.title}
        </Text>
        <Spoiler
          maxHeight={50}
          showLabel={
            <Text c="blue" size="sm">
              Show more
            </Text>
          }
          hideLabel={
            <Text c="blue" size="sm">
              Show less
            </Text>
          }
          fz={12}
        >
          {item.description}
        </Spoiler>
      </Box>
    </Group>
  </React.Fragment>
);

export default RuleItem;
