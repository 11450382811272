import React from 'react';

import { Tooltip, FileButton } from '@mantine/core';

interface UploadFileButtonProps {
  onUpload: (file: File | null) => void;
  children: React.ReactElement;
}

const UploadFileButton: React.FC<UploadFileButtonProps> = ({
  onUpload,
  children,
}) => {
  return (
    <Tooltip label="Upload new snapshot">
      <FileButton onChange={onUpload} accept="pdf">
        {(fileButtonProps) =>
          React.isValidElement(children)
            ? React.cloneElement(children, fileButtonProps)
            : children
        }
      </FileButton>
    </Tooltip>
  );
};

export default UploadFileButton;
