import { request } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

const CopilotService = {
  sendMessage: async (message: string, documentSnapshotId: number) => {
    return request({
      url: `${pathPrefix}copilot/`,
      method: 'post',
      data: { message, document_snapshot_id: documentSnapshotId },
    });
  },
};

export default CopilotService;
