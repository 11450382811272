import { Button as MantineButton } from '@mantine/core';
import { ButtonProps } from '@mantine/core';

import styles from './Button.module.scss';

interface CustomButtonProps extends ButtonProps {
  variant?: 'link' | ButtonProps['variant'];
  id?: string;
  onClick?: () => Promise<void> | void;
}

export function Button({ variant, className, ...props }: CustomButtonProps) {
  const buttonClass = variant === 'link' ? styles.linkButton : '';

  return (
    <MantineButton
      {...props}
      variant={variant === 'link' ? 'subtle' : variant}
      className={`${buttonClass} ${className || ''}`}
    />
  );
}

export default Button;
