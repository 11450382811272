import React, { useState, useEffect } from 'react';

import {
  Flex,
  Grid,
  Title,
  Modal,
  Card,
  Button,
  Text,
  Stack,
  Group,
  Select,
  SelectProps,
  ComboboxItem,
  LoadingOverlay,
} from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons-react';
import DocumentService from 'Api/documentService';
import FileViewer from 'Components/file-viewer/FileViewer';
import UploadFileButton from 'Components/upload-file-button/UploadFileButton';
import useLoading from 'Src/hooks/useLoading';
import {
  DocumentDataType,
  DocumentSnapshotType,
  SnapshotRequestType,
} from 'Types/docTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

import classes from '../Documents.module.scss';

interface CustomSelectItem extends ComboboxItem {
  description?: string;
}

type PreviewDocumentDrawerProps = {
  openDocumentPreview: boolean;
  handlePreviewDocument: { open: () => void; close: () => void };
  documentDetails: DocumentDataType | null;
  redirectToAssessment: (document: DocumentDataType) => void;
  onFileSelect: (file: File | null, document: DocumentDataType) => void;
  documentsList: DocumentDataType[];
};

const PreviewDocumentDrawer: React.FC<PreviewDocumentDrawerProps> = ({
  openDocumentPreview,
  handlePreviewDocument,
  documentDetails,
  onFileSelect,
  redirectToAssessment,
}) => {
  const [snapshots, setSnapshots] = useState([]);
  const [currentSnapshot, setCurrentSnapshot] =
    useState<DocumentSnapshotType | null>(null);
  const [loading, handleLoading] = useLoading(false);

  const {
    created_at: createdAt,
    updated_at: updatedAt,
    document,
    file,
    org,
    updated_by: updatedBy,
  }: Partial<DocumentSnapshotType> = currentSnapshot || {};

  const fetchDocumentSnapshots = async (id: number) => {
    try {
      handleLoading.start();
      const response = await DocumentService.getSnapshotsByDocumentId(id);
      const { data } = response.data;
      const snapshot = data.find(
        (item: SnapshotRequestType) =>
          item.id === documentDetails?.favorite_snapshot.id
      );
      setCurrentSnapshot(snapshot);
      setSnapshots(data);
    } catch (e) {
      showErrorNotification('Error fetching snapshot details');
    } finally {
      handleLoading.stop();
    }
  };

  useEffect(() => {
    if (openDocumentPreview && documentDetails?.id)
      fetchDocumentSnapshots(documentDetails?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDocumentPreview]);

  if (!documentDetails) return;

  const renderSelectOption: SelectProps['renderOption'] = ({
    option,
  }: {
    option: CustomSelectItem;
  }) => {
    return (
      <Group flex="1" gap="xs">
        <Text fw={500} size="sm">
          {option.label}
        </Text>
        {option.description && <Text size="xs">{option.description}</Text>}
      </Group>
    );
  };

  const renderDocumentDetails = () => {
    const details = [
      { name: 'Document Name:', value: document?.filename },
      {
        name: 'Updated at:',
        value: updatedAt && transformDateString(updatedAt),
      },
      {
        name: 'Created at:',
        value: createdAt && transformDateString(createdAt),
      },
      {
        name: 'Last updated by:',
        value: updatedBy && updatedBy.email,
      },
      {
        name: 'Organisation:',
        value: org && org.name,
      },
    ];

    return (
      <Flex flex={1} pos="relative" direction="column">
        <Title order={4}>Document details</Title>
        <Grid gutter="md" style={{ width: '100%', marginTop: 24 }}>
          {details.map((item) => {
            return (
              <React.Fragment>
                <Grid.Col span={5}>
                  <Text size="sm">{item.name}</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Text size="sm">{item.value}</Text>
                </Grid.Col>
              </React.Fragment>
            );
          })}
        </Grid>
      </Flex>
    );
  };

  return (
    <Modal
      opened={openDocumentPreview}
      onClose={() => handlePreviewDocument.close()}
      centered
      withCloseButton={false}
      size="100%"
    >
      <Grid>
        <Grid.Col span={8}>
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'pink', type: 'bars' }}
          />

          <Stack h="100%">
            <Group justify="space-between" align="center">
              <Group align="center">
                <Select
                  placeholder="Select Snapshot"
                  label="Select Snapshot"
                  data={snapshots.map((item: DocumentSnapshotType) => ({
                    value: `${item.id}`,
                    label: `${item.document.filename}`,
                    description: `${transformDateString(item.created_at)}`,
                  }))}
                  onChange={(option) => {
                    const doc = snapshots.find(
                      (item: DocumentSnapshotType) => item.id === Number(option)
                    );
                    if (doc) {
                      setCurrentSnapshot(doc);
                    }
                  }}
                  value={currentSnapshot ? `${currentSnapshot.id}` : null}
                  renderOption={renderSelectOption}
                  withAsterisk
                  className={classes.alignHorizontal}
                />
              </Group>
              <UploadFileButton
                onUpload={(file: File | null) => {
                  onFileSelect(file, documentDetails);
                  setCurrentSnapshot(null);
                }}
              >
                <Button variant="white" leftSection={<IconPlus size="14" />}>
                  Add
                </Button>
              </UploadFileButton>
            </Group>
            {file && <FileViewer height="100%" fileUrl={file} />}
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack h="85vh" gap={'lg'}>
            <React.Fragment>
              <Group justify="flex-end">
                <IconX
                  size="24"
                  onClick={() => handlePreviewDocument.close()}
                  cursor={'pointer'}
                />
              </Group>
              <Card shadow="sm" padding="lg" radius="md" withBorder h="100%">
                <Flex w={'100%'} direction="column" h="100%">
                  {renderDocumentDetails()}
                  <Flex pt={24} w="100%" bottom={'0'} bg="white" pos="sticky">
                    <Flex w="100%" justify="space-between" gap={'sm'}>
                      <UploadFileButton
                        onUpload={(file: File | null) => {
                          onFileSelect(file, documentDetails);
                          setCurrentSnapshot(null);
                        }}
                      >
                        <Button
                          size="sm"
                          variant="outline"
                          w={'30%'}
                          leftSection={<IconPlus size="14" />}
                        >
                          <Text size="sm" fw={700}>
                            Add
                          </Text>
                        </Button>
                      </UploadFileButton>

                      <Button
                        size="sm"
                        variant="filled"
                        w={'70%'}
                        onClick={() => redirectToAssessment(documentDetails)}
                      >
                        <Text size="sm" fw={700}>
                          Assessment
                        </Text>
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </React.Fragment>
          </Stack>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default PreviewDocumentDrawer;
