import React, { useState } from 'react';

import { Flex, Input, ActionIcon, Box } from '@mantine/core';
import { CloseButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSearch, IconFilter, IconArrowsSort } from '@tabler/icons-react';
import { FilterItemProps } from 'Src/types/commonTypes';
import { FiltersType } from 'Src/types/commonTypes';

import FilterContainer from './FilterContainer';

interface ToolBarProps {
  showSearch?: boolean;
  onSearch: (value: string) => void;
  showFilterIcon?: boolean;
  showSortIcon?: boolean;
  onApplyFilters?: (filters: FiltersType) => void;
  totalEntities?: number;
  filtersConfig?: FilterItemProps[];
  paginationEnabled?: boolean;
  onToggleFilters?: () => void;
}

const ToolBar: React.FC<ToolBarProps> = ({
  showSearch,
  onSearch,
  showFilterIcon = false,
  showSortIcon = false,
  onApplyFilters,
  totalEntities,
  filtersConfig,
  onToggleFilters,
}) => {
  const [filtersOpened, handleFiltersOpened] = useDisclosure(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    onSearch(value);
  };

  const handleToggleFilters = () => {
    handleFiltersOpened.toggle();
    onToggleFilters?.();
  };

  const applyFilters = (filters: FiltersType) => {
    onApplyFilters?.(filters);
    handleToggleFilters();
  };

  const onClearFilters = (toggleRequired = true) => {
    onApplyFilters?.({});
    if (toggleRequired) handleToggleFilters();
  };

  return (
    <Box w="100%">
      <Flex align="center" justify={'space-between'} w="100%">
        <Flex gap="sm" w="60%">
          {showSearch && (
            <Input
              size="sm"
              placeholder="Search..."
              leftSection={<IconSearch size={18} />}
              onChange={handleSearchChange}
              w="60%"
              value={searchText}
              rightSectionPointerEvents="all"
              rightSection={
                <CloseButton
                  aria-label="Clear input"
                  onClick={() => {
                    setSearchText('');
                    onSearch('');
                  }}
                  style={{ display: searchText ? undefined : 'none' }}
                />
              }
            />
          )}
          {showFilterIcon && (
            <ActionIcon
              size={'input-sm'}
              variant={filtersOpened ? 'filled' : 'default'}
              color="black"
              onClick={handleToggleFilters}
            >
              <IconFilter stroke={1} />
            </ActionIcon>
          )}
          {showSortIcon && (
            <ActionIcon size={'input-sm'} variant="default" disabled>
              <IconArrowsSort stroke={1} />
            </ActionIcon>
          )}
          {/* Custom left side actions */}
        </Flex>
        {/* Custom right side actions */}
      </Flex>
      <Box>
        <FilterContainer
          filtersOpened={filtersOpened}
          applyFilters={applyFilters}
          handleToggleFilters={handleToggleFilters}
          totalEntities={totalEntities}
          filtersConfig={filtersConfig}
          onClearFilters={onClearFilters}
        />
        {/* we can add the custom filters here */}
      </Box>
    </Box>
  );
};

export default ToolBar;
