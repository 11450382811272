import { useNavigate } from 'react-router-dom';

import { Button } from '@mantine/core';
import NotFound from 'Src/pages/not-found/NotFound';

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate('/', { replace: true });
    window.location.reload();
  };

  return (
    <NotFound
      title="We're Sorry! Error Occurred"
      description={`Something went wrong on our end. Please try again later or return to the home page.`}
      action={<Button onClick={navigateToHome}>Go Back to Home</Button>}
    />
  );
};

export default ErrorPage;
