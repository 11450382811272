import React from 'react';

import {
  Flex,
  Text,
  Button,
  useMantineTheme,
  Stack,
  Group,
  ActionIcon,
  Tooltip,
} from '@mantine/core';
import { IconStar, IconTrash, IconCircleX } from '@tabler/icons-react';

import classes from '../../Assessment.module.scss';
import DeleteAction from './DeleteActions';

type SnapshotActionsProps = {
  onClosePreview: () => void;
  compareSnapshots: () => void;
  makeItPrimary: () => void;
  position: string;
  snapshotId?: number;
  primarySnapshotId?: number;
  deleteSnapshotVersion?: (deletedSnapshotId: string) => void;
  showCompareAction?: boolean;
};

const SnapshotActions: React.FC<SnapshotActionsProps> = ({
  primarySnapshotId,
  snapshotId,
  makeItPrimary,
  compareSnapshots,
  position,
  onClosePreview,
  deleteSnapshotVersion,
  showCompareAction,
}) => {
  const theme = useMantineTheme();
  const isPrimary = primarySnapshotId === snapshotId;

  if (!snapshotId) return;

  const viewModeActions = [
    {
      key: 'primary',
      actionIcon: IconStar,
      onClick: makeItPrimary,
      label: 'Make it primary',
    },
    {
      key: 'delete',
      actionIcon: IconTrash,
      onClick: null,
      label: 'Delete snapshot',
      customComponent: () => {
        return (
          <DeleteAction
            size="xs"
            variant="subtle"
            color="primary"
            style={{ width: '60%', height: '60%' }}
            optionValue={snapshotId?.toString()}
            className={classes.actionIcon}
            callbackOnComplete={deleteSnapshotVersion}
            callbackOnClick={(event) => {
              event.stopPropagation();
            }}
          />
        );
      },
    },
    {
      key: 'close',
      actionIcon: IconCircleX,
      onClick: () => close(),
      label: 'Close Preview',
    },
  ];

  return (
    <React.Fragment>
      {position === 'header' ? (
        <Flex w="100%" justify={'center'} wrap="wrap">
          <Group>
            <Button variant="primary" size="xs" onClick={compareSnapshots}>
              Compare
            </Button>
            {viewModeActions.map((item) => {
              return (
                <Tooltip label={item.label}>
                  {item.customComponent ? (
                    item.customComponent()
                  ) : (
                    <ActionIcon
                      variant="subtle"
                      size="xs"
                      aria-label="Settings"
                      color="pink"
                      className={classes.actionIcon}
                      onClick={item.onClick}
                    >
                      <item.actionIcon
                        style={{ width: '60%', height: '60%' }}
                        stroke={1.5}
                      />
                    </ActionIcon>
                  )}
                </Tooltip>
              );
            })}
          </Group>
        </Flex>
      ) : (
        <Flex py="md" w="100%" bottom="0" bg="white" pos="sticky">
          <Stack gap="xs" w="100%">
            {!isPrimary && (
              <Button.Group w="100%">
                <Button
                  variant="default"
                  w={showCompareAction ? '65%' : '90%'}
                  size="lg"
                  style={{ border: '1px solid #091A29' }}
                  onClick={makeItPrimary}
                >
                  <Group gap="0">
                    <ActionIcon variant="transparent" color="black" size="xs">
                      <IconStar stroke={1.5} />
                    </ActionIcon>
                    <Text ml="8" size="sm" fw={500}>
                      {'Make it Primary'}
                    </Text>
                  </Group>
                </Button>
                {showCompareAction && (
                  <Button
                    color={theme.colors.primary[6]}
                    size="lg"
                    w="30%"
                    style={{ border: '1px solid #091A29' }}
                    onClick={compareSnapshots}
                  >
                    <Text size="sm" fw={500}>
                      {'Compare'}
                    </Text>
                  </Button>
                )}
                <Button
                  variant="default"
                  size="lg"
                  style={{ border: '1px solid #091A29' }}
                >
                  <DeleteAction
                    size={'sm'}
                    variant={'subtle'}
                    color={'black'}
                    optionValue={snapshotId?.toString()}
                    callbackOnComplete={deleteSnapshotVersion}
                    callbackOnClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </Button>
              </Button.Group>
            )}
            <Button
              color={theme.colors.tertiary[5]}
              size="lg"
              fullWidth
              style={{ border: '1px solid #091A29' }}
              onClick={onClosePreview}
            >
              <Text size="sm" fw={700}>
                {'Close Preview'}
              </Text>
            </Button>
          </Stack>
        </Flex>
      )}
    </React.Fragment>
  );
};

export default SnapshotActions;
