import React from 'react';

import { Box, Group, Skeleton, Divider } from '@mantine/core';

interface SkeletonItemProps {
  loading: boolean;
}

const SkeletonItem: React.FC<SkeletonItemProps> = ({ loading }) => {
  if (!loading) return;

  return (
    <Box>
      {[1, 2, 3, 4, 5, 6, 7].map((item) => (
        <React.Fragment key={item}>
          <Group wrap="nowrap" align="flex-start">
            <Skeleton height={20} width={20} mt="lg" />
            <Box w={'100%'}>
              <Skeleton height={20} mt="md" width="100%" />
              <Skeleton height={40} mt="sm" width="100%" />
            </Box>
          </Group>
          <Divider mb={12} mt={16} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SkeletonItem;
