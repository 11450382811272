import { isBoolean } from 'lodash';
import React, { useState } from 'react';

import {
  Flex,
  Box,
  Text,
  Select,
  Group,
  Button,
  Transition,
  Collapse,
  Pill,
  MultiSelect,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { FilterItemProps, FiltersType } from 'Types/commonTypes';

const scaleY = {
  in: { opacity: 1, transform: 'scaleY(1)' },
  out: { opacity: 0, transform: 'scaleY(0)' },
  common: { transformOrigin: 'top' },
  transitionProperty: 'transform, opacity',
};

interface FilterContainerProps {
  filtersOpened: boolean;
  applyFilters?: (filters: FiltersType) => void;
  handleToggleFilters: () => void;
  totalEntities?: number;
  filtersConfig?: FilterItemProps[];
  onClearFilters: (toggleRequired?: boolean) => void;
}
const FilterContainer: React.FC<FilterContainerProps> = ({
  filtersOpened,
  applyFilters,
  handleToggleFilters,
  totalEntities,
  filtersConfig,
  onClearFilters,
}) => {
  const [showFilters, handleShowFilters] = useDisclosure();
  const [filters, setFilters] = useState<FiltersType>({});
  const currentAppliedFiltersLength = Object.keys(filters).length;
  const showSelectedFilters = currentAppliedFiltersLength > 0 && !filtersOpened;
  const theme = useMantineTheme();

  const selectComponent = (
    filterItem: FilterItemProps,
    currentValue: string | null
  ) => {
    return (
      <Select
        size="xs"
        key={filterItem.key}
        placeholder={filterItem.label}
        label={filterItem.label}
        data={filterItem.values}
        value={currentValue?.toString() ?? null}
        onChange={(value: string | null) => {
          let newVal: string | null | boolean;
          if (!value) return;
          /* Since SelectItem only supports string values */
          if (value === 'true') newVal = true;
          else if (value === 'false') newVal = false;
          else newVal = value;

          setFilters(
            (prevFilters) =>
              ({
                ...prevFilters,
                [filterItem.key]: newVal,
              }) as FiltersType
          );
        }}
        comboboxProps={{ zIndex: 1000 }}
      />
    );
  };

  const multiSelectComponent = (
    filterItem: FilterItemProps,
    currentValue: string[]
  ) => {
    return (
      <MultiSelect
        size="xs"
        key={filterItem.key}
        placeholder={filterItem.label}
        label={filterItem.label}
        data={filterItem.values}
        value={currentValue}
        onChange={(value) => {
          setFilters(
            (prevFilters) =>
              ({
                ...prevFilters,
                [filterItem.key]: value,
              }) as FiltersType
          );
        }}
        comboboxProps={{ zIndex: 1000 }}
      />
    );
  };

  const renderFilterLabel = (
    currentValue: FiltersType[keyof FiltersType],
    key: string
  ) => {
    if (isBoolean(currentValue)) {
      return filtersConfig?.find((item) => item.key === key)?.activeLabels?.[
        Number(currentValue).toString()
      ] as string;
    } else if (Array.isArray(currentValue)) {
      return currentValue.join(',');
    }
    return currentValue as string;
  };

  const clearFilters = (toggle = true) => {
    setFilters({});
    onClearFilters(toggle);
  };

  return (
    <React.Fragment>
      <Transition
        mounted={filtersOpened}
        transition={scaleY}
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <Box my={8} style={styles}>
            <Text mb={2} size="xs" fw={700}>
              Filter by:
            </Text>
            <Flex gap="sm" wrap="wrap" align="start" direction="column">
              <Group gap="xs" grow align="center">
                {/* Currently supports the select and multi-select but we can extend to further types */}
                {filtersConfig?.map((filterItem) => {
                  const filterKey: string = filterItem.key;
                  return filterItem?.multi
                    ? multiSelectComponent(
                        filterItem,
                        filters[filterKey] as string[]
                      )
                    : selectComponent(
                        filterItem,
                        filters[filterKey] as string | null
                      );
                })}
              </Group>
              <Group align="end">
                <Button
                  size="xs"
                  variant="outline"
                  onClick={() => applyFilters?.(filters)}
                >
                  Apply
                </Button>
                <Text
                  size="xs"
                  c="primary"
                  fw={500}
                  onClick={() => clearFilters(true)}
                  style={{ cursor: 'pointer', alignSelf: 'center' }}
                >
                  Clear filters
                </Text>
              </Group>
            </Flex>
          </Box>
        )}
      </Transition>
      {/* Showing the selected filters */}
      {showSelectedFilters && (
        <Box
          mt={8}
          px={18}
          py={9}
          bg={theme.colors.gray[0]}
          style={{ borderRadius: '5px' }}
        >
          <Flex justify="space-between">
            <Flex
              gap={4}
              style={{ cursor: 'pointer' }}
              align={'center'}
              onClick={() => handleShowFilters.toggle()}
            >
              <Text size="sm" fw={500}>
                {currentAppliedFiltersLength} Filters applied{' '}
                {`( ${totalEntities} results )`}
              </Text>
              {showFilters ? <IconChevronUp /> : <IconChevronDown />}
            </Flex>
            <Text
              size="xs"
              c="primary"
              fw={500}
              onClick={() => clearFilters(false)}
              style={{ cursor: 'pointer' }}
            >
              Clear filters
            </Text>
          </Flex>
          <Collapse
            in={showFilters}
            transitionDuration={200}
            transitionTimingFunction="linear"
          >
            <Box mt={'sm'}>
              <Group>
                {Object.entries(filters).map((item) => {
                  const key = item[0];
                  const currentValue = item[1];
                  return (
                    <Pill size="lg" radius={0} key={currentValue?.toString()}>
                      {renderFilterLabel(currentValue, key)}
                    </Pill>
                  );
                })}
              </Group>
              <Text
                size={'xs'}
                mt={12}
                c="primary"
                fw={500}
                style={{ cursor: 'pointer' }}
                onClick={handleToggleFilters}
              >
                Add/edit filters
              </Text>
            </Box>
          </Collapse>
        </Box>
      )}
    </React.Fragment>
  );
};

export default FilterContainer;
