import { Login, Documents, Assessment, NotFound, Batches } from 'Src/pages';
import { RouteConfigType } from 'Types/commonTypes';

import { LayoutVariant } from './constants';
import Rules from './pages/rules/Rules';

// currently app support 1 level of nested routes

const protectedRoutes: RouteConfigType[] = [
  {
    path: '/assessment/:batchId/:docId',
    component: Assessment,
    layout: LayoutVariant.CUSTOM,
  },
  {
    path: '/batches',
    nestedRoutes: [
      { component: Batches, isIndex: true },
      { path: ':batchId/documents', component: Documents },
    ],
  },
  { path: '/documents', component: Documents, slug: 'documents' },
  { path: '/rules', component: Rules, slug: 'rules' },
  { path: '/', component: Documents, isIndex: true, slug: 'documents' },
];

const publicRoutes: RouteConfigType[] = [
  { path: '/login', component: Login },
  { path: '/*', component: NotFound },
];

export { protectedRoutes, publicRoutes, LayoutVariant };
