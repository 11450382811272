import dayjs from 'dayjs';
import React, { useState, useMemo } from 'react';

import {
  Drawer,
  Box,
  Text,
  Group,
  Divider,
  Stack,
  Pagination,
  Flex,
  ScrollArea,
} from '@mantine/core';
import { Select } from '@mantine/core';
import { DateValue, DatePicker } from '@mantine/dates';
import AuditService from 'Api/auditService';
import FilterContainer from 'Components/filters/FilterContainer';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { LAST_2_DAYS_RANGE } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { AuditLogsState } from 'Types/auditLogTypes';
import { FiltersType } from 'Types/commonTypes';
import { showErrorNotification } from 'Utils/notifications';
import relativeTime from 'dayjs/plugin/relativeTime';

// Extend dayjs with relativeTime plugin to enable human-readable time differences (e.g., "2 hours ago")
dayjs.extend(relativeTime);

// Utility function to safely access nested properties
const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

type EntityType = {
  id: string | number;
  [key: string]: any;
};

interface AuditLogProps {
  isDrawer?: boolean;
  open?: boolean;
  onClose?: () => void;
  contentType: string;
  entities?: {
    currentEntity: number | null;
    list: EntityType[];
    key: string;
    contentType: string;
    name: string;
  };
}

const AuditLogs: React.FC<AuditLogProps> = ({
  isDrawer = true,
  open,
  onClose,
  contentType,
  entities,
}) => {
  const initialFilters = entities?.currentEntity
    ? { object_id: entities.currentEntity?.toString() }
    : {};
  const defaultFilters = {
    dateRange: LAST_2_DAYS_RANGE as [DateValue, DateValue],
  };
  const [auditLogs, setAuditLogs] = useState<AuditLogsState>({
    logs: [],
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, handleLoading] = useLoading(false);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const itemsPerPage = 100;

  const entityWithIdsMap = useMemo(() => {
    const entitesList = entities?.list;
    return entitesList
      ? entitesList.reduce(
          (acc: Record<string | number, typeof entity>, entity) => {
            acc[entity.id] = entity;
            return acc;
          },
          {} as Record<string | number, EntityType>
        )
      : {};
  }, [entities?.list]);

  const fetchAuditLogs = async (newFilters: FiltersType) => {
    try {
      handleLoading.start();
      const apiFilters = { ...newFilters };

      // Check if both timestamp__gte and timestamp__lte are present
      if (!apiFilters.timestamp__gte || !apiFilters.timestamp__lte) {
        showErrorNotification('Date range is required for audit logs');
        return;
      }

      let fetchContentType = contentType;
      if (newFilters['object_id']) {
        fetchContentType = `${entities?.contentType}.${newFilters['object_id']}`;
      }

      const resp = await AuditService.getLogs({
        filters: {
          content_type: fetchContentType,
          page: currentPage,
          ...apiFilters,
        },
      });
      const { results, count } = resp.data;
      setAuditLogs({ logs: results, total: count });
    } catch (e) {
      showErrorNotification('Failed to fetch the audit logs');
      // Handle error
    } finally {
      handleLoading.stop();
    }
  };

  const applyFilters = (newFilters: FiltersType) => {
    const appliedFilters: Record<string, any> = {};

    Object.entries(newFilters).forEach(([key, value]) => {
      if (key === 'dateRange' && Array.isArray(value) && value.length === 2) {
        const [startDate, endDate] = value;
        if (startDate) {
          appliedFilters.timestamp__gte = dayjs(startDate).toISOString();
        }
        if (endDate) {
          appliedFilters.timestamp__lte = dayjs(endDate).toISOString();
        } else {
          appliedFilters.timestamp__lte = appliedFilters.timestamp__gte;
        }
      } else if (value !== null && value !== undefined && value !== '') {
        appliedFilters[key] = value;
      }
    });
    setFilters(appliedFilters);
    fetchAuditLogs(appliedFilters);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchAuditLogs({ ...filters, page });
  };

  const generateActorsData = () => {
    return Array.from(
      new Map(
        auditLogs.logs
          .filter((log) => log.actor && log.actor.id && log.actor.username)
          .map((log) => [
            log.actor.id,
            {
              id: log.actor.id,
              username: log.actor.username,
            },
          ])
      ).values()
    ).map(({ id, username }) => ({
      label: `${username} (${id})`,
      value: id.toString(),
    }));
  };

  const generateEntitiesData = () => {
    if (!entities) return [];
    return [
      {
        label: entities.name,
        key: 'object_id',
        component: Select,
        props: {
          data:
            entities?.list.map((entity) => ({
              value: entity.id.toString(),
              label: `${getNestedValue(entity, entities?.key)} (${entity.id})`,
            })) || [],
          placeholder: 'Select entity',
          clearable: true,
          searchable: true,
        },
      },
    ];
  };

  // Define a new filtersConfig for specific filters
  const filtersConfig = [
    {
      label: 'Action',
      key: 'action',
      component: Select,
      props: {
        data: [
          { label: 'Create', value: 'create' },
          { label: 'Update', value: 'update' },
          { label: 'Delete', value: 'delete' },
        ],
        placeholder: 'Select action',
        clearable: true,
      },
    },
    {
      label: 'Actor',
      key: 'actor',
      component: Select,
      props: {
        data: generateActorsData(),
        placeholder: 'Select actor',
        clearable: true,
        searchable: true,
      },
    },
    {
      label: 'Date Range',
      key: 'dateRange',
      component: DatePicker,
      props: {
        type: 'range',
        placeholder: 'Pick date range',
        clearable: true,
      },
    },
    ...(entities ? generateEntitiesData() : []),
  ];

  const renderLogDescription = (log: any) => {
    const object = entityWithIdsMap[log?.object_id];
    const entityName =
      object && entities
        ? getNestedValue(object, entities?.key)
        : log.content_type;

    return (
      <Text size="sm" fw={500}>
        {log.actor && log.actor.username ? (
          <>
            <Text span fw={600} c="primary">
              {log.actor.username}
            </Text>{' '}
            has {log.action}d the{' '}
          </>
        ) : (
          <>The </>
        )}
        <Text span fw={600} c="primary">
          {entityName}
        </Text>
        {!log.actor && <> has been {log.action}d</>}
      </Text>
    );
  };

  const displayContent = (
    <Stack px="sm" h="100%">
      <Box
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'white',
        }}
      >
        <FilterContainer
          filtersConfig={filtersConfig}
          applyFilters={applyFilters}
          initialFilters={initialFilters}
          defaultFilters={defaultFilters}
        />
      </Box>

      <ScrollArea style={{ flex: 1 }}>
        <Group justify="flex-end" mt="sm" mb="sm">
          <Pagination
            total={Math.ceil(auditLogs.total / itemsPerPage)}
            value={currentPage}
            onChange={handlePageChange}
            size={'sm'}
          />
        </Group>
        <Stack gap="md">
          {auditLogs.logs.length === 0 && (
            <Flex justify="center" align={'center'}>
              <Text size="sm" mt="md">
                No logs
              </Text>
            </Flex>
          )}
          <LoadingOverlay visible={loading} />
          {auditLogs.logs.map((log) => (
            <Box key={log.id}>
              {renderLogDescription(log)}
              <Stack gap="xs" mt="sm">
                {log.changes.map((change: any, index: number) => (
                  <Box key={index}>
                    {change.field && (
                      <Text size="sm">
                        <span style={{ fontWeight: 600 }}>{change.field}</span>:{' '}
                        {change.old} → {change.new}
                      </Text>
                    )}
                  </Box>
                ))}
              </Stack>
              <Group mt="xs" justify="space-between">
                <Text size="xs" color="dimmed">
                  {dayjs(log.timestamp).fromNow()}
                </Text>
                <Text size="xs" color="dimmed">
                  {dayjs(log.timestamp).format('MMMM D, YYYY | HH:mm:ss')}
                </Text>
              </Group>

              <Divider my="sm" />
            </Box>
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );

  if (isDrawer) {
    return (
      <Drawer
        opened={!!open}
        onClose={() => onClose?.()}
        title={
          <Text p="xs" fw={700}>
            Activity Logs
          </Text>
        }
        position="right"
        size="lg"
      >
        {displayContent}
      </Drawer>
    );
  }

  return (
    <Box p="md">
      <Text size="md" fw={700} mb="xs" px="xs">
        Activity Logs
      </Text>
      {displayContent}
    </Box>
  );
};

export default AuditLogs;
