import React from 'react';

import { Group, Text, rem } from '@mantine/core';
import {
  Dropzone as MantineDropZone,
  DropzoneProps,
  PDF_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
} from '@mantine/dropzone';
import '@mantine/dropzone/styles.css';
import { IconUpload, IconX, IconFileTypePdf } from '@tabler/icons-react';
import { Assessment } from 'Constants/index';
import { MBToBytes } from 'Utils/transform';

import classes from './Dropzone.module.scss';

type DropzoneCustomProps = {
  title: string;
  description: string;
  elementIcon?: React.ReactNode;
  setUploadedFile: (file: File) => void;
};

export function Dropzone({
  setUploadedFile,
  ...props
}: Partial<DropzoneProps> & DropzoneCustomProps) {
  return (
    <MantineDropZone
      className={classes.dropzoneContainer}
      onDrop={(files) => setUploadedFile(files[0])}
      onReject={(files) => console.log('rejected files', files)}
      maxSize={MBToBytes(Assessment.MAX_FILE_SIZE)}
      accept={[...PDF_MIME_TYPE, ...MS_EXCEL_MIME_TYPE, 'text/csv']}
      {...props}
    >
      <Group
        justify="center"
        gap="xl"
        mih={150}
        style={{ pointerEvents: 'none' }}
      >
        <MantineDropZone.Accept>
          <IconUpload
            style={{
              width: rem(52),
              height: rem(52),
              color: 'var(--mantine-color-blue-6)',
            }}
            stroke={1.5}
          />
        </MantineDropZone.Accept>
        <MantineDropZone.Reject>
          <IconX
            style={{
              width: rem(52),
              height: rem(52),
              color: 'var(--mantine-color-red-6)',
            }}
            stroke={1.5}
          />
        </MantineDropZone.Reject>
        <MantineDropZone.Idle>
          <IconFileTypePdf
            style={{
              width: rem(52),
              height: rem(52),
              color: 'var(--mantine-color-dimmed)',
            }}
            stroke={1.5}
          />
        </MantineDropZone.Idle>

        <div>
          <Text size="xl" inline>
            {props.title}
          </Text>
          <Text size="sm" c="dimmed" inline mt={7}>
            {props.description}
          </Text>
        </div>
      </Group>
    </MantineDropZone>
  );
}
