import React, { useState } from 'react';

import {
  TextInput,
  Button,
  Drawer,
  Flex,
  Title,
  Input,
  CloseButton,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDeviceFloppy, IconFile } from '@tabler/icons-react';
import CoreService from 'Api/coreService';
import DocumentService from 'Api/documentService';
import { Dropzone } from 'Components/dropzone/Dropzone';
import { SnapshotRequestType } from 'Types/docTypes';

type AddDocumentProps = {
  batchId?: number;
  openAddDocumentDrawer: boolean;
  handleAddDocumentDrawer: { open: () => void; close: () => void };
  uploadFile: (file: File, snapshot: SnapshotRequestType) => void;
  refresh?: () => void;
};

const AddDocumentDrawer: React.FC<AddDocumentProps> = ({
  batchId,
  openAddDocumentDrawer,
  handleAddDocumentDrawer,
  uploadFile,
  refresh,
}) => {
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  const resetDrawer = () => {
    setFileName('');
    setFile(null);
  };

  const handleSubmit = async () => {
    try {
      let orgId;
      if (!file) throw new Error('Please select a valid file');
      // @todo currently 1st org is selected by default, figure out a way to select org
      const { data } = await CoreService.getOrgs();
      if (data?.results?.length > 0) {
        orgId = data.results[0].id;
      }

      const postData = {
        batch_id: batchId ?? null,
        org_id: orgId,
        files: [
          {
            document_id: null,
            filename: fileName,
            file_original_name: file.name,
            file_mimetype: file.type,
          },
        ],
      };
      const response = await DocumentService.createDocuments(postData);
      if (response && response.data) {
        const { documents } = response.data;
        // As of now, we only supports the sigle file upload
        const { snapshot } = documents[0];
        uploadFile(file, snapshot);
        resetDrawer();
        handleAddDocumentDrawer.close();
        if (refresh) refresh();
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Something went wrong',
        color: 'red',
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFileName(event.target.value);
  };

  return (
    <Drawer
      opened={openAddDocumentDrawer}
      onClose={() => handleAddDocumentDrawer.close()}
      title={
        <Flex justify="space-between">
          <Title order={4}> Add New Document</Title>
        </Flex>
      }
      position="right"
    >
      <form>
        <div>
          <TextInput
            value={fileName}
            name="filename"
            label="File Name"
            placeholder="eg: Marketing Document"
            required
            onChange={handleChange}
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setFileName('')}
              />
            }
          />
          {file ? (
            <Input
              rightSectionPointerEvents="all"
              mt={10}
              defaultValue={file.name}
              leftSection={<IconFile size={20} />}
              rightSection={
                <CloseButton
                  aria-label="Clear input"
                  onClick={() => setFile(null)}
                />
              }
            />
          ) : (
            <Dropzone
              mt="md"
              title="Drag file here or click to select files"
              description="File should not exceed 5mb, only pdf & excel files accepted."
              setUploadedFile={setFile}
            />
          )}
        </div>
        <Flex mt={30} justify="flex-end">
          <Button
            onClick={handleSubmit}
            ml={20}
            variant="filled"
            size="compact-sm"
            leftSection={<IconDeviceFloppy size={20} />}
            disabled={!fileName || !file}
          >
            Submit
          </Button>
        </Flex>
      </form>
    </Drawer>
  );
};

export default AddDocumentDrawer;
