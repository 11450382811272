import { isBoolean } from 'lodash';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import {
  Flex,
  Box,
  Text,
  Select,
  Group,
  Button,
  Transition,
  Collapse,
  Pill,
  MultiSelect,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import RuleService from 'Api/ruleService';
import { RuleFilters } from 'Constants/index';
import { FilterItemProps, FiltersType } from 'Types/commonTypes';
import { TagType } from 'Types/ruleTypes';
import { showErrorNotification } from 'Utils/notifications';

const scaleY = {
  in: { opacity: 1, transform: 'scaleY(1)' },
  out: { opacity: 0, transform: 'scaleY(0)' },
  common: { transformOrigin: 'top' },
  transitionProperty: 'transform, opacity',
};

interface FilterContainerProps {
  filtersOpened: boolean;
  onClearFilters: () => void;
  applyFilters: () => void;
  filters: FiltersType;
  setFilters: Dispatch<SetStateAction<FiltersType>>;
  handleFiltersOpened: {
    open: () => void;
    close: () => void;
    toggle: () => void;
  };
  totalRules: number;
  defaultFilters?: FiltersType;
  loading?: boolean;
}

const formatFilterAndResultText = (
  filterCount: number,
  resultCount: number
) => {
  const filterText = `${filterCount} ${filterCount === 1 ? 'Filter' : 'Filters'} applied`;
  const resultText = `${resultCount} ${resultCount === 1 ? 'result' : 'results'}`;
  return `${filterText} (${resultText})`;
};

const FilterContainer: React.FC<FilterContainerProps> = ({
  filtersOpened,
  onClearFilters,
  filters,
  setFilters,
  applyFilters,
  handleFiltersOpened,
  totalRules,
  defaultFilters,
  loading,
}) => {
  const [showFilters, handleShowFilters] = useDisclosure();
  const currentAppliedFiltersLength = Object.keys(filters).length;
  const showSelectedFilters = currentAppliedFiltersLength > 0 && !filtersOpened;
  const [ruleTags, setRuleTags] = useState<string[]>([]);
  const theme = useMantineTheme();

  const activeLabels: Record<string, string> = {
    '0': 'Inactive',
    '1': 'Active',
  };

  const filtersData = [
    {
      label: 'Rule Tag',
      values: ruleTags ?? [],
      key: 'temp_group_name',
      multi: true,
    },
    ...RuleFilters,
  ];

  const fetchRuleTags = async () => {
    try {
      const resp = await RuleService.getRuleTags();
      const tags = resp.data
        .map((i: TagType) => i.temp_group_name)
        .filter((tag: string) => tag);
      setRuleTags(['All Rules', ...tags]);
    } catch (e) {
      showErrorNotification('Error fetching tags');
    }
  };

  useEffect(() => {
    fetchRuleTags();
  }, []);

  useEffect(() => {
    if (defaultFilters) {
      setFilters(defaultFilters);
      handleFiltersOpened.toggle();
      applyFilters();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const selectComponent = (
    filterItem: FilterItemProps,
    currentValue: string | null
  ) => {
    return (
      <Select
        size="xs"
        key={filterItem.key}
        placeholder={filterItem.label}
        label={filterItem.label}
        data={filterItem.values}
        value={currentValue?.toString() ?? null}
        onChange={(value: string | null) => {
          let newVal: string | null | boolean;
          if (!value) return;
          if (value === 'true') newVal = true;
          else if (value === 'false') newVal = false;
          else newVal = value;

          setFilters(
            (prevFilters) =>
              ({
                ...prevFilters,
                [filterItem.key]: newVal,
              }) as FiltersType
          );
        }}
        comboboxProps={{ zIndex: 1000 }}
      />
    );
  };

  const multSelectComponent = (
    filterItem: FilterItemProps,
    currentValue: string[]
  ) => {
    return (
      <MultiSelect
        size="xs"
        key={filterItem.key}
        placeholder={filterItem.label}
        label={filterItem.label}
        data={filterItem.values}
        value={currentValue}
        onChange={(value) => {
          setFilters(
            (prevFilters) =>
              ({
                ...prevFilters,
                [filterItem.key]: value,
              }) as FiltersType
          );
        }}
        comboboxProps={{ zIndex: 1000 }}
      />
    );
  };

  return (
    <React.Fragment>
      <Transition
        mounted={filtersOpened}
        transition={scaleY}
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <Box my={8} style={styles}>
            <Text mb={2} size="xs" fw={700}>
              Filter by:
            </Text>
            <Group gap="xs" grow align="start">
              {filtersData.map((filterItem) => {
                const filterKey: string = filterItem.key;
                return filterItem?.multi
                  ? multSelectComponent(
                      filterItem,
                      filters[filterKey] as string[]
                    )
                  : selectComponent(
                      filterItem,
                      filters[filterKey] as string | null
                    );
              })}
            </Group>
            <Group mt={16}>
              <Button size="xs" variant="outline" onClick={applyFilters}>
                Apply
              </Button>
              <Text
                size="xs"
                c="primary"
                fw={500}
                onClick={onClearFilters}
                style={{ cursor: 'pointer' }}
              >
                Clear filters
              </Text>
            </Group>
          </Box>
        )}
      </Transition>
      {/* Showing the selected filters */}
      {showSelectedFilters && (
        <Box
          mt={8}
          px={18}
          py={9}
          bg={theme.colors.gray[0]}
          style={{ borderRadius: '5px' }}
          pos="relative"
        >
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
            loaderProps={{ color: 'pink', type: 'bars', size: 20 }}
          />
          <Flex justify="space-between">
            <Flex
              gap={4}
              style={{ cursor: 'pointer' }}
              align={'center'}
              onClick={() => handleShowFilters.toggle()}
            >
              <Text size="sm" fw={500}>
                {formatFilterAndResultText(
                  currentAppliedFiltersLength,
                  totalRules
                )}
              </Text>
              {showFilters ? <IconChevronUp /> : <IconChevronDown />}
            </Flex>
            <Text
              size="xs"
              c="primary"
              fw={500}
              onClick={onClearFilters}
              style={{ cursor: 'pointer' }}
            >
              Clear filters
            </Text>
          </Flex>
          <Collapse
            in={showFilters}
            transitionDuration={200}
            transitionTimingFunction="linear"
          >
            <Box mt={'sm'}>
              <Group>
                {Object.entries(filters).map((item) => {
                  const currentValue = item[1];
                  let label: string;
                  if (isBoolean(currentValue)) {
                    label = activeLabels[
                      Number(currentValue).toString()
                    ] as string;
                  } else if (Array.isArray(currentValue))
                    label = currentValue.join(',');
                  else {
                    label = currentValue as string;
                  }
                  return (
                    <Pill size="lg" radius={0} key={currentValue?.toString()}>
                      {label}
                    </Pill>
                  );
                })}
              </Group>
              <Text
                size={'xs'}
                mt={12}
                c="primary"
                fw={500}
                style={{ cursor: 'pointer' }}
                onClick={handleFiltersOpened.toggle}
              >
                Add/edit filters
              </Text>
            </Box>
          </Collapse>
        </Box>
      )}
    </React.Fragment>
  );
};

export default FilterContainer;
