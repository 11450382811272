export enum CrudOperations {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

// const crudMessages = (type, operation) => {
//   return `New ${type} added successfully`
// }

const Messages = {
  // policy : {
  //   NEW_POLICY_SUCCESS: crudMessages("Policy", ),

  // },
  auth: {
    ATLEAST_ONE_TENANT: {
      title: 'No Tenant',
      message: 'User should be associated with atleast one tenant',
    },
    INVALID_CREDENTIALS: {
      title: 'Invalid Credentials',
      message: 'Please enter valid credentials',
    },
    UNAUTHORIZED: {
      title: 'Action Forbidden',
      message: 'Please authorize yourself',
    },
  },
};

export default Messages;
