import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import * as Sentry from '@sentry/react';
import AuthRoute from 'Components/auth-route/AuthRoute';
import DefaultLayout from 'Components/layouts/Default/DefaultLayout';
import Error from 'Src/pages/error/Error';
import { RootState } from 'Src/redux/store';

// import 'mantine-datatable/styles.layer.css';
import './App.css';
import { LayoutVariant, protectedRoutes, publicRoutes } from './routes.config';
import { fetchTheme, resolver } from './theme.config';

export function App() {
  const { user } = useSelector((state: RootState) => state.auth);

  const { DEFAULT, CUSTOM } = LayoutVariant;

  const routesWithCustomLayout = protectedRoutes.filter(
    (route) => route.layout && route.layout === CUSTOM
  );

  const routesWithDefaultLayout = protectedRoutes.filter(
    (route) => !route.layout || route.layout === DEFAULT
  );

  return (
    <MantineProvider
      cssVariablesResolver={resolver}
      theme={fetchTheme(user)}
      defaultColorScheme="auto"
      classNamesPrefix="zen"
    >
      <Sentry.ErrorBoundary fallback={<Error />}>
        <ModalsProvider>
          <Notifications zIndex={10000} position="bottom-left" />
          <Routes>
            <Route
              element={
                <AuthRoute>
                  <DefaultLayout />
                </AuthRoute>
              }
            >
              {routesWithDefaultLayout.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component ? <route.component /> : null}
                >
                  {route.nestedRoutes?.map((nestedRoute) => (
                    <Route
                      key={index}
                      {...(nestedRoute?.isIndex
                        ? { index: true }
                        : { path: nestedRoute.path })}
                      element={
                        nestedRoute.component ? <nestedRoute.component /> : null
                      }
                    />
                  ))}
                </Route>
              ))}
            </Route>
            {routesWithCustomLayout.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  route.component ? (
                    <AuthRoute>
                      <route.component />
                    </AuthRoute>
                  ) : null
                }
              >
                {route.nestedRoutes?.map((nestedRoute) => (
                  <Route
                    key={index}
                    {...(nestedRoute?.isIndex
                      ? { index: true }
                      : { path: nestedRoute.path })}
                    element={
                      nestedRoute.component ? <nestedRoute.component /> : null
                    }
                  />
                ))}
              </Route>
            ))}
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component ? <route.component /> : null}
              />
            ))}
          </Routes>
        </ModalsProvider>
      </Sentry.ErrorBoundary>
    </MantineProvider>
  );
}
export default App;
