import { request, generateCRUDOps, API_PATH } from './httpService';

const DocumentService = {
  ...generateCRUDOps(`/api/v1/reviews/`, 'documents'),
  getDocumentsByBatch: async (id: number) => {
    return request({
      url: `${API_PATH.REVIEWS}batches/${id}/documents/`,
      method: 'get',
    });
  },
  bulkUploadFinish: async (snapshotids: number[]) => {
    return request({
      url: `${API_PATH.REVIEWS}documents/bulk-finish-upload/`,
      method: 'post',
      data: { snapshot_ids: snapshotids },
    });
  },
  getSnapshotsByDocumentId: async (id: number) => {
    return request({
      url: `${API_PATH.REVIEWS}documents/${id}/snapshots/`,
      method: 'get',
    });
  },
};

export default DocumentService;
