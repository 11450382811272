import { UploadFileDetailType } from 'Types/docTypes';

export const constructPostDataForFileUpload = (data: UploadFileDetailType) => ({
  batch_id: data.batchId,
  org_id: data.orgId,
  files: [
    {
      document_id: data.docId,
      filename: data.name,
      file_original_name: data.file.name,
      file_mimetype: data.file.type,
    },
  ],
});
