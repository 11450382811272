import { notifications } from '@mantine/notifications';

// const notificationVariants = {
//     error: {
//       title: 'Error',
//       color: 'red',
//     },
//     success: {
//       title: 'Success',
//       color: 'teal',
//     },
//     loading: {
//       loading: true,
//     },
//   };

const showErrorNotification = (message: string, ...rest: any[]) =>
  notifications.show({
    message,
    title: 'Error',
    color: 'red',
    ...rest,
  });

const showSuccessNotification = (message: string, ...rest: any[]) =>
  notifications.show({
    message,
    color: 'teal',
    title: 'Success',
    ...rest,
  });

const showLoadingNotification = (title: string, message: string) =>
  notifications.show({
    loading: true,
    title,
    message,
  });

const showInfoNotification = (title: string, message: string) =>
  notifications.show({
    title,
    message,
  });

const clearNotificationsQueue = () => notifications.cleanQueue();

export {
  showErrorNotification,
  showLoadingNotification,
  showSuccessNotification,
  showInfoNotification,
  clearNotificationsQueue,
};
