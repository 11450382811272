import { RuleExecutionStatus } from 'Constants/index';
import { RuleEvalType } from 'Types/ruleTypes';
import { getRationalesFromExecTree } from 'Utils/transform';

type PropTypes = {
  ruleEval: RuleEvalType | null;
};

// Ref for the logic here https://www.notion.so/zenafide/Show-Rationale-to-the-user-about-rules-execution-c7e5ff32c0ca464289a4185848cf1503?pvs=4#9b1b7c4f97a04060a8992678b9e50116
const useRuleStatus = ({ ruleEval }: PropTypes) => {
  if (!ruleEval) return {};

  const ruleEvalStatus = ruleEval?.status?.id;
  const ruleExecutionStatus =
    ruleEval?.latest_execution?.execution_status || null;
  if (!ruleExecutionStatus) return {};
  const outputContext = ruleEval?.latest_execution?.output_context;

  const ruleIgnored = ruleExecutionStatus === RuleExecutionStatus.IGNORED;
  const isManualRule = ruleEval?.rule?.expression === null;
  const isRuleFailed = ruleExecutionStatus === RuleExecutionStatus.FAILED;
  const isRuleCompleted = ruleExecutionStatus === RuleExecutionStatus.SUCCEEDED;

  const isRuleInProgress = [
    RuleExecutionStatus.NOT_STARTED,
    RuleExecutionStatus.QUEUED,
    RuleExecutionStatus.EXECUTING,
  ].includes(ruleExecutionStatus);

  // show either compilation(if pending) error or execution(if completed execution) error
  const errorMessage =
    (isRuleFailed || isRuleInProgress) &&
    (outputContext.compile_error || outputContext.exec_error);

  const rationales =
    isRuleCompleted && getRationalesFromExecTree(outputContext.exec_tree);

  const showSelectStatusManually = isManualRule && !ruleExecutionStatus;
  const isRuleManuallyOverridden =
    !isRuleInProgress &&
    !isRuleFailed &&
    ruleEval?.latest_execution?.eval_status !== ruleEvalStatus;

  return {
    isManualRule,
    isRuleFailed,
    isRuleInProgress,
    ruleIgnored,
    showSelectStatusManually,
    isRuleManuallyOverridden,
    errorMessage,
    rationales,
  };
};

export default useRuleStatus;
