import { debounce } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';

import { Box, Group } from '@mantine/core';
import '@mantine/dates/styles.css';
import {
  FilterValue,
  GenericFilterConfig,
  GenericFiltersType,
} from 'Types/commonTypes';

import AddFilterButton from './AddFilterButton';
import ClearFiltersButton from './ClearFiltersButton';
import FilterPopover from './FilterPopover';

/*
  TODO:
    1. isDelete prop at individual filter
    2. Default values for filters at individual level

    Please add if anything is missed
*/

interface FilterContainerProps<T extends Record<string, FilterValue>> {
  filtersConfig: GenericFilterConfig<T[keyof T]>[];
  applyFilters: (filters: GenericFiltersType<T>) => void;
  onClearFilters?: () => void;
  showAllByDefault?: boolean;
  defaultFilters?: Partial<GenericFiltersType<T>>;
  initialFilters?: Partial<GenericFiltersType<T>>;
}

const FilterContainer = <T extends Record<string, FilterValue>>({
  filtersConfig,
  applyFilters,
  onClearFilters,
  showAllByDefault = true,
  defaultFilters = {},
  initialFilters = {},
}: FilterContainerProps<T>) => {
  const [filters, setFilters] = useState<GenericFiltersType<T>>(
    {} as GenericFiltersType<T>
  );
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [openFilterPopover, setOpenFilterPopover] = useState<string | null>(
    null
  );
  const displayClearButton = useMemo(() => {
    const defaultFilterKeys = Object.keys(defaultFilters);
    if (!showAllByDefault && defaultFilterKeys.length < activeFilters.length)
      return true;
    return Object.keys(filters).some((key) => {
      if (!defaultFilterKeys.includes(key)) {
        return true;
      }
      return (
        JSON.stringify(filters[key]) !== JSON.stringify(defaultFilters[key])
      );
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters, defaultFilters, filtersConfig, activeFilters]);

  const showAddFilterButton = useMemo(() => {
    if (showAllByDefault) return false;
    return Object.keys(filtersConfig).length > activeFilters.length;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filtersConfig, activeFilters]);

  useEffect(() => {
    let filtersToSave = [];
    if (showAllByDefault) {
      filtersToSave = filtersConfig.map((f) => f.key);
    } else {
      filtersToSave = filtersConfig.slice(0, 2).map((f) => f.key);
    }
    if (defaultFilters) {
      setFilters(defaultFilters as GenericFiltersType<T>);
      const defaultFilterKeys = Object.keys(defaultFilters);
      filtersToSave = [...defaultFilterKeys, ...filtersToSave];
    }

    if (initialFilters) {
      const initialFilterKeys = Object.keys(initialFilters);
      filtersToSave = [...new Set([...filtersToSave, ...initialFilterKeys])];
    }
    setActiveFilters(filtersToSave);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...defaultFilters,
      ...initialFilters,
    }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const clearFilters = () => {
    if (defaultFilters) {
      setFilters(defaultFilters as GenericFiltersType<T>);
      if (!showAllByDefault) {
        setActiveFilters(Object.keys(defaultFilters));
        return;
      }
      const defaultFilterKeys = Object.keys(defaultFilters);
      const filtersToShow = filtersConfig
        .filter((f) => !defaultFilterKeys.includes(f.key))
        .map((f) => f.key);
      setActiveFilters([...defaultFilterKeys, ...filtersToShow]);
    } else {
      setFilters({} as GenericFiltersType<T>);
      setActiveFilters(showAllByDefault ? filtersConfig.map((f) => f.key) : []);
    }
    if (onClearFilters) onClearFilters();
  };

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const debouncedApplyFilters = useCallback(
    debounce((newFilters: GenericFiltersType<T>) => {
      applyFilters(newFilters);
    }, 300),
    [applyFilters]
  );

  useEffect(() => {
    debouncedApplyFilters(filters);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filters]);

  const addFilter = (filterKey: string) => {
    if (!activeFilters.includes(filterKey)) {
      setActiveFilters([...activeFilters, filterKey]);
      setOpenFilterPopover(null);
    }
  };

  const removeFilter = (filterKey: string) => {
    if (!showAllByDefault) {
      setActiveFilters(activeFilters.filter((key) => key !== filterKey));
    }
    setFilters((prev) => {
      const newFilters = { ...prev };
      delete newFilters[filterKey as keyof T];
      return newFilters as GenericFiltersType<T>;
    });
  };

  const handleFilterChange = (
    filterKey: keyof T,
    value: T[keyof T],
    closePopUp: boolean = true
  ) => {
    setFilters((prev) => {
      const newFilters = { ...prev, [filterKey]: value };
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        delete newFilters[filterKey as keyof T];
      }
      return newFilters;
    });
    if (closePopUp) setOpenFilterPopover(null);
  };

  const renderFilters = () => {
    return activeFilters
      .sort((a, b) => {
        const valueA = filters[a];
        const valueB = filters[b];
        if (valueA && !valueB) return -1;
        if (!valueA && valueB) return 1;
        return 0;
      })
      .map((filterKey) => {
        const filter = filtersConfig.find((f) => f.key === filterKey);
        return filter ? (
          <FilterPopover
            key={filter.key}
            filter={filter}
            selectedValues={filters[filter.key]}
            filtersConfig={filtersConfig}
            handleFilterChange={handleFilterChange}
            removeFilter={removeFilter}
            showAllByDefault={showAllByDefault}
            openFilterPopover={openFilterPopover}
            setOpenFilterPopover={setOpenFilterPopover}
          />
        ) : null;
      });
  };

  return (
    <Box my="xs">
      <Group display={'flex'} style={{ alignItems: 'center' }} gap="xs">
        {renderFilters()}

        {showAddFilterButton && (
          <AddFilterButton
            filtersConfig={filtersConfig}
            activeFilters={activeFilters}
            addFilter={addFilter}
            openFilterPopover={openFilterPopover}
            setOpenFilterPopover={setOpenFilterPopover}
          />
        )}

        <ClearFiltersButton
          clearFilters={clearFilters}
          displayClearButton={displayClearButton}
        />
      </Group>
    </Box>
  );
};

export default FilterContainer;
