import { useNavigate } from 'react-router-dom';

import { Container, Title, Text, Button, Group } from '@mantine/core';

import classes from './NotFound.module.scss';
import { Illustration } from './components/Illustration';

type NotFoundProps = {
  title?: string;
  description?: string;
  action?: React.ReactNode;
  bgImage?: boolean;
};

const NotFound: React.FC<NotFoundProps> = ({
  title,
  description,
  action,
  bgImage,
}) => {
  const navigate = useNavigate();
  const defaultTitle = title || 'Nothing to see here';
  const defaultDescription =
    description ||
    'Page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error contact support.';
  const defaultAction = action || (
    <Button onClick={() => navigate('/login')} size="md">
      Take me back to home page
    </Button>
  );
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        {bgImage && <Illustration className={classes.image} />}
        <div className={classes.content}>
          <Title className={classes.title}>{defaultTitle}</Title>
          <Text
            c="dimmed"
            size="lg"
            ta="center"
            className={classes.description}
          >
            {defaultDescription}
          </Text>
          <Group justify="center" mt="md">
            {defaultAction}
          </Group>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
