import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Modal,
  Stack,
  Text,
  Flex,
  ActionIcon,
  Box,
  Anchor,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCircleCheck,
  IconCircleX,
  IconCornerUpRightDouble,
  IconCircleCheckFilled,
  IconMessageCircle,
} from '@tabler/icons-react';
import { CommentThreadsService } from 'Api/commentThreadsService';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import Textarea from 'Components/text-area/Textarea';
import { CommentStatus } from 'Constants/comments';
import { Mode } from 'Constants/index';
import useLoading from 'Src/hooks/useLoading';
import { RootState } from 'Src/redux/store';
import { CommentType } from 'Types/commentTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

const customStyles = (coordinates: { x: number; y: number }) => ({
  inner: {
    position: 'absolute' as const,
    left: `${coordinates.x}px`,
    top: `${coordinates.y}px`,
    transform: 'none',
    width: 'max-content',
    background: 'none',
    padding: 0,
  },
  body: {
    width: 'max-content',
  },
  content: {
    width: 'max-content',
  },
  overlay: {
    background: 'none',
  },
});

interface Coordinates {
  x: number;
  y: number;
}

interface MessagePopupProps {
  coordinates: Coordinates | null;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (message: string) => void;
  activeCommentThreadId: string | null;
  commentsConfig?: {
    mode: string;
    enableComments: boolean;
  };
}

const MessagePopup: React.FC<MessagePopupProps> = ({
  coordinates,
  isOpen,
  onClose,
  onSubmit,
  activeCommentThreadId,
  commentsConfig,
}) => {
  const [message, setMessage] = useState('');
  const [commentThread, setCommentThread] = useState<CommentType | null>(null);
  const [loading, handleLoading] = useLoading(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showReply, handleReply] = useDisclosure();
  const discussion = commentThread?.discussion?.latest_posts || [];
  const [showReplies, handleShowReplies] = useDisclosure(false);
  const theme = useMantineTheme();
  const user = useSelector((state: RootState) => state.auth.user);

  const mainPost =
    discussion.length > 1 ? discussion[discussion.length - 1] : discussion[0];
  const replies = discussion.length > 1 ? discussion.slice(0, -1) : [];

  useEffect(() => {
    if (activeCommentThreadId) {
      fetchCommentThread(activeCommentThreadId);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [activeCommentThreadId]);

  if (!coordinates) return null;

  const handleSubmit = async () => {
    if (activeCommentThreadId) {
      handleLoading.start();
      try {
        await CommentThreadsService.addPost(Number(activeCommentThreadId), {
          content: message,
        });
        fetchCommentThread(activeCommentThreadId);
        setMessage('');
      } catch (e) {
        console.log(e);
      } finally {
        handleLoading.stop();
      }
    } else {
      onSubmit(message);
      setMessage('');
      handleOnClose();
    }
  };

  const handleOnClose = () => {
    setCommentThread(null);
    handleReply.close();
    onClose();
  };

  const fetchCommentThread = async (id: string) => {
    handleLoading.start();
    try {
      const resp = await CommentThreadsService.getCommentThreadsById(
        Number(id)
      );
      if (resp?.data) {
        setCommentThread(resp?.data);
      }
    } catch (e) {
      console.error('Error fetching comment thread:', e);
    } finally {
      handleLoading.stop();
    }
  };

  const handleGoToComments = () => {
    if (commentThread) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('commentId', commentThread?.id?.toString());
      searchParams.delete('annotationId');
      navigate(`${location.pathname}?${searchParams.toString()}`);
      handleOnClose();
    }
  };

  const resolveComment = async () => {
    if (!commentThread) return;
    handleLoading.start();
    try {
      const response = await CommentThreadsService.updateCommentThreads(
        commentThread.id,
        {
          status: CommentStatus.RESOLVED,
        }
      );
      if (response?.data) {
        setCommentThread(response.data);
      }
    } catch (e: any) {
      showErrorNotification(e.message || 'Error resolving comment');
    } finally {
      handleLoading.stop();
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={handleOnClose}
      withCloseButton={false}
      styles={customStyles(coordinates)}
    >
      <Stack w="300px" gap="0">
        {loading && <LoadingOverlay visible={loading} />}
        <Flex justify="flex-end" align="center" w="100%">
          <Flex gap="0.3rem" justify={'flex-end'}>
            {activeCommentThreadId && (
              <React.Fragment>
                {commentsConfig?.mode === Mode.EDIT && (
                  <Tooltip label="Resolve comment">
                    {commentThread?.status === CommentStatus.RESOLVED ? (
                      <ActionIcon c="#429103" variant="subtle">
                        <IconCircleCheckFilled size={25} />
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        c="#D6DAD2"
                        variant="transparent"
                        onClick={resolveComment}
                      >
                        <IconCircleCheck size={25} />
                      </ActionIcon>
                    )}
                  </Tooltip>
                )}
                <Tooltip label="Go to comments">
                  <ActionIcon
                    c="#D6DAD2"
                    variant="transparent"
                    onClick={handleGoToComments}
                  >
                    <IconCornerUpRightDouble size={30} />
                  </ActionIcon>
                </Tooltip>
              </React.Fragment>
            )}
            <Tooltip label="Close">
              <ActionIcon
                c="#D6DAD2"
                variant="transparent"
                onClick={handleOnClose}
              >
                <IconCircleX size={30} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex direction={'column'} w="100%" mt="xs">
          {commentThread && (
            <Box w="100%">
              <Flex
                justify="space-between"
                align="baseline"
                wrap="wrap"
                gap="xs"
              >
                <Tooltip label={mainPost.created_by.username}>
                  <Text size="sm" fw="700" w="60%" truncate>
                    {mainPost.created_by.username}
                  </Text>
                </Tooltip>
                <Text size="0.6rem" c="dimmed" style={{ flexShrink: 0 }}>
                  {transformDateString(mainPost.created_at, true, true)}
                </Text>
              </Flex>
              <Text size="sm">{mainPost.content}</Text>
              <Flex align="center" gap="xs" mt="sm">
                {replies.length > 0 &&
                  (!showReplies ? (
                    <ActionIcon
                      variant="subtle"
                      onClick={handleShowReplies.toggle}
                    >
                      <IconMessageCircle size={20} />
                      <Text size="xs" ml={4}>
                        {replies.length}
                      </Text>
                    </ActionIcon>
                  ) : (
                    <Anchor
                      size="sm"
                      c="primary"
                      fw="500"
                      underline="never"
                      onClick={handleShowReplies.toggle}
                    >
                      {`Hide replies`}
                    </Anchor>
                  ))}
                {commentsConfig?.mode === Mode.EDIT && !showReply && (
                  <Anchor
                    size="sm"
                    c="primary"
                    fw="500"
                    underline="never"
                    onClick={handleReply.toggle}
                  >
                    Reply
                  </Anchor>
                )}
              </Flex>
            </Box>
          )}

          {showReplies && replies.length > 0 && (
            <Stack
              mt="sm"
              bg={theme.colors.gray[2]}
              style={{ borderRadius: '4px', overflowY: 'auto' }}
              py="xs"
              mah="200px"
              gap="xs"
            >
              {replies.map((reply) => (
                <Box key={reply.id} px="xs">
                  <Flex direction="column" gap="xs">
                    <Flex
                      justify="space-between"
                      align="baseline"
                      wrap="wrap"
                      gap="xs"
                    >
                      <Tooltip label={reply.created_by.username}>
                        <Text size="sm" fw="700" w="60%" truncate>
                          {reply.created_by.username}
                        </Text>
                      </Tooltip>
                      <Text size="0.6rem" c="dimmed" style={{ flexShrink: 0 }}>
                        {transformDateString(mainPost.created_at, true, true)}
                      </Text>
                    </Flex>
                    <Text size="sm" style={{ wordBreak: 'break-word' }}>
                      {reply.content}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Stack>
          )}

          {(showReply || !activeCommentThreadId) && (
            <Flex mt="sm" w="100%" direction={'column'}>
              <Text size="sm" fw="700" truncate mb="xs">
                {user?.username}
              </Text>
              <Textarea
                variant="filled"
                placeholder="Enter your comment"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                w={'100%'}
              />
              <Flex justify={'flex-end'} w="100%" gap="xs">
                <Anchor
                  size="sm"
                  c="primary"
                  fw="500"
                  mt="sm"
                  underline="never"
                  onClick={handleSubmit}
                >
                  Submit
                </Anchor>
                {showReply && (
                  <Anchor
                    size="sm"
                    c="primary"
                    fw="500"
                    mt="sm"
                    underline="never"
                    onClick={handleReply.toggle}
                  >
                    Cancel
                  </Anchor>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </Stack>
    </Modal>
  );
};

export default MessagePopup;
