import React from 'react';
import { useSelector } from 'react-redux';

import { Avatar, Group, Text } from '@mantine/core';
import { RootState } from 'Src/redux/store';
import { getUsernameFromEmail } from 'Utils/transform';

type UserLabelProps = {
  hideAvatar?: boolean;
  hideEmail?: boolean;
  color?: string;
};

const UserLabel: React.FC<UserLabelProps> = ({
  hideAvatar = false,
  hideEmail = false,
  color,
}) => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <Group>
      {!hideAvatar && (
        <Avatar
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-9.png"
          radius="xl"
        />
      )}

      <div style={{ flex: 1 }}>
        {/* @todo : SHOW DYNAMIC ROLES */}
        {/* <Text size="xs" fw={500} c="dimmed">
          PRODUCT MANAGER 
        </Text> */}
        {!hideEmail && user?.email && (
          <Text size="sm" c={color ? color : ''}>
            {getUsernameFromEmail(user.email)}
          </Text>
        )}
      </div>
    </Group>
  );
};

export default UserLabel;
