import { Button } from '@mantine/core';

interface ClearFiltersButtonProps {
  displayClearButton: boolean;
  clearFilters: () => void;
}

const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({
  clearFilters,
  displayClearButton,
}) => {
  if (!displayClearButton) return null;

  return (
    <Button variant="subtle" color="red" onClick={clearFilters} size="xs">
      Clear filters
    </Button>
  );
};

export default ClearFiltersButton;
