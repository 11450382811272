import cx from 'clsx';
import {
  DataTable as MantineDataTable,
  DataTableProps,
  DataTableColumn,
} from 'mantine-datatable';

import 'mantine-datatable/styles.layer.css';

import classes from './DataTable.module.scss';

type CustomDataTableProps<T = Record<string, unknown>> = DataTableProps<T> & {
  disableDataTable?: boolean;
  columns: DataTableColumn<T>[];
};

const DataTable = <T,>({
  disableDataTable,
  columns = [],
  ...props
}: CustomDataTableProps<T>) => {
  return (
    <MantineDataTable
      {...props}
      columns={columns}
      className={cx(
        { [classes.disableDataTable]: disableDataTable },
        classes.tableContainer
      )}
    />
  );
};

export default DataTable;
