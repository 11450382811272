import React, { useEffect, useRef, ChangeEvent, KeyboardEvent } from 'react';

import { Button } from '@mantine/core';
import { IconSend2 } from '@tabler/icons-react';

import classes from './ChatInput.module.scss';

interface ChatSearchInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  handleSendMessage: () => void;
  inputProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  float?: boolean;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
}

const ChatInput: React.FC<ChatSearchInputProps> = ({
  inputValue,
  setInputValue,
  handleSendMessage,
  inputProps = {},
  float,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.rows = 1;
    }
  }, []);

  useEffect(() => {
    handleTextboxSize();
  }, [inputValue]);

  const handleTextboxSize = () => {
    const textarea = textareaRef.current;

    if (!textarea) return;

    // Increase the rows if content is overflowing
    while (textarea.scrollHeight > textarea.clientHeight && textarea.rows < 6) {
      textarea.rows++;
    }
    // Try to decrease the rows to see if content still fits.
    // Ensure rows don't go below a certain number (e.g., 1).
    while (textarea.rows > 1) {
      textarea.rows--;
      if (textarea.scrollHeight > textarea.clientHeight) {
        // We've decreased too much, so add one row back.
        textarea.rows++;
        break;
      }
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && inputValue && inputValue.trim() !== '') {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div
      className={`${float ? classes.float : ''} ${classes.chatInputContainer}`}
    >
      <textarea
        ref={textareaRef}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className={classes.chatSearchInput}
        placeholder="Message Copilot "
        {...inputProps}
      />
      <Button
        variant="filled"
        className={classes.chatSearchButton}
        onClick={handleSendMessage}
      >
        <IconSend2 />
      </Button>
    </div>
  );
};

export default ChatInput;
