// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import activeAssessmentReducer from 'Slices/activeAssessmentSlice';

import activeDocumentReducer from './slices/activeDocumentSlice';
import authReducer from './slices/authSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    activeDocument: activeDocumentReducer,
    activeAssessment: activeAssessmentReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
