import React, { useEffect, useState } from 'react';

import { TextInput, Button, Select, Drawer, Flex, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconDeviceFloppy } from '@tabler/icons-react';
import BatchService from 'Api/batchService';
import CoreService from 'Api/coreService';
import { BatchType } from 'Types/batchTypes';
import { OrgType } from 'Types/commonTypes';

type AddBatchProps = {
  openAddBatchDrawer: boolean;
  setOpenAddBatchDrawer: (open: boolean) => void;
};

const initialFormValues: any = {
  name: '',
};

const AddBatchDrawer: React.FC<AddBatchProps> = ({
  openAddBatchDrawer,
  setOpenAddBatchDrawer,
}) => {
  const [addFormValues, setAddFormValues] = useState<BatchType>({
    ...initialFormValues,
  });
  const [orgs, setOrgs] = useState<OrgType[]>([]);

  useEffect(() => {
    setAddFormValues(initialFormValues);
    fetchOrgs();
  }, []);

  const fetchOrgs = async () => {
    const { data } = await CoreService.getOrgs();
    if (data && data.results) {
      setOrgs(data.results);
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await BatchService.createBatches({
        ...addFormValues,
      });
      if (response && response.data) {
        notifications.show({
          title: 'Batch Added',
          message: 'Batch has been added successfully',
          color: 'teal',
        });
        setOpenAddBatchDrawer(false);
        setAddFormValues(initialFormValues);
      }
    } catch (error: any) {
      notifications.show({
        title: 'Error',
        message: error.message || 'Something went wrong',
        color: 'red',
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setAddFormValues({
      ...addFormValues,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Drawer
      opened={openAddBatchDrawer}
      onClose={() => setOpenAddBatchDrawer(false)}
      title={
        <Flex justify="space-between">
          <Title order={4}> Add New Batch</Title>
        </Flex>
      }
      position="right"
    >
      <form>
        <div>
          <TextInput
            value={addFormValues.name}
            name="name"
            label="Name"
            placeholder="Batch Name"
            onChange={handleChange}
          />
          <Select
            mt="md"
            label="Select Organization"
            name="org_id"
            placeholder="Select Organization"
            required
            onChange={(_value, option) =>
              setAddFormValues((prev) => ({
                ...prev,
                org_id: parseInt(option.value),
              }))
            }
            data={orgs.map((org) => ({
              value: org.id.toString(),
              label: org.name,
            }))}
          />
          <Flex mt={30} justify="flex-end">
            <Button
              onClick={handleSubmit}
              ml={20}
              variant="outline"
              size="compact-sm"
              leftSection={<IconDeviceFloppy size={20} />}
            >
              Add
            </Button>
          </Flex>
        </div>
      </form>
    </Drawer>
  );
};

export default AddBatchDrawer;
