import { request, generateCRUDOps, API_PATH } from './httpService';

const pathPrefix = '/api/v1/reviews';

const RuleService = {
  ...generateCRUDOps(API_PATH.REVIEWS, 'rules'),
  updateRuleEvalStatus: async (ruleEvalId: number, status: number) => {
    return request({
      url: `${pathPrefix}/rule-evals/${ruleEvalId}/`,
      method: 'put',
      data: { status },
    });
  },
  getRuleExecutionStatus: async () => {
    return request({
      url: `/api/v1/meta/enums/policies:ruleexecutionstatus/`,
      method: 'get',
    });
  },
  reinitializeEvals: async (ruleEvalId: number) => {
    return request({
      url: `${pathPrefix}/rule-executions/${ruleEvalId}/rerun/`,
      method: 'post',
    });
  },
  // this will reset rule eval status
  triggerRuleEvaluation: async (ruleEvalId: number, reset_status: boolean) => {
    return request({
      url: `${pathPrefix}/rule-evals/${ruleEvalId}/retrigger/`,
      method: 'post',
      data: { reset_status },
    });
  },
  searchRule: async (searchText: string) => {
    return request({
      url: `${pathPrefix}/rules/search/?q=${searchText}`,
      method: 'get',
    });
  },
  getRuleTags: async () => {
    return request({
      url: `${pathPrefix}/rules/tags`,
      method: 'get',
    });
  },
};

export default RuleService;
