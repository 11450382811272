import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppShell } from '@mantine/core';
import NavbarLinksGroup from 'Components/navbar-links-group/NavbarLinksGroup';
import { MENU } from 'Constants/menu';
import { RootState } from 'Src/redux/store';
import { protectedRoutes } from 'Src/routes.config';

import classes from './SideBar.module.scss';

const SideBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [activeMenu, setActiveMenu] = useState<string | undefined>();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  useEffect(() => {
    if (pathname) {
      const currentPath = pathname.split('/')[1];
      if (currentPath === '' && isAuthenticated) {
        const indexRoute = protectedRoutes.find((route) => route.isIndex);
        if (indexRoute) {
          // highlight index route
          return setActiveMenu(indexRoute.slug);
        }
      }
      setActiveMenu(currentPath);
    }
  }, [pathname, isAuthenticated]);

  const links = MENU.map((item) => (
    <NavbarLinksGroup
      handleMenuClick={setActiveMenu}
      activeMenu={activeMenu}
      key={item.slug}
      {...item}
    />
  ));

  return (
    <AppShell.Navbar p="md" pt="xs">
      <div className={classes.navbarMain}>{links}</div>
      <div className={classes.footer}></div>
    </AppShell.Navbar>
  );
};

export default SideBar;
