import { RuleIdWithResetStatus } from 'Types/ruleTypes';

import { API_PATH, request, generateCRUDOps } from './httpService';

const API_VERSION = 'v1';
const pathPrefix = `/api/${API_VERSION}/reviews/`;

console.log(API_PATH, 'API_PATH');

// ...generateCRUDOps(`/api/v1/reviews/`, 'documents'),

const AssessmentService = {
  ...generateCRUDOps(pathPrefix, 'assessments'),
  getAssessmentByInfo: async (
    batch_id: number,
    doc_id: number,
    doc_snapshot_id: number
  ) => {
    // return dummyData.getAssessmentByInfo;
    return request({
      url: `${pathPrefix}assessments/?batch=${batch_id}&document=${doc_id}&document_snapshot=${doc_snapshot_id}`,
      method: 'get',
    });
  },

  //remove later
  // getAssessmentsById: async (assessmentId: number) => {
  //   return dummyData.getAssessmentsById;
  // },
  // api/v1/reviews/assessments/17/reinitialize/
  //   {
  //     "v2": true,
  //     "reset_rule_eval_status": False, // based on user intent
  //     "reparse": False // based on user intent
  // }
  reinitializePolicyEvals: async (
    assessmentId: number,
    rules?: RuleIdWithResetStatus
  ) => {
    return request({
      url: `${pathPrefix}assessments/${assessmentId}/reinitialize/`,
      method: 'post',
      data: {
        v2: true,
        reparse: false,
        reset_rule_eval_status: true,
        ...(rules && { rules }),
      },
    });
  },
  triggerRuleEvaluation: async (ruleId: number, reportId: number) => {
    return request({
      url: `${pathPrefix}/rule-evals/${reportId}/retrigger/`,
      method: 'post',
      data: { rule_id: ruleId, report_id: reportId },
    });
  },
  triggerPolicyEvaluation: async (policy_eval_id: number, eval_id: number) => {
    return request({
      url: `${pathPrefix}/evaluations/${eval_id}/policy-evals/${policy_eval_id}/retrigger/`,
      method: 'post',
    });
  },
};

export default AssessmentService;
