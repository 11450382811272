import React from 'react';

import {
  Flex,
  Text,
  Group,
  Tooltip,
  ActionIcon,
  Button,
  Box,
  CSSProperties,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';
import SnapshotService from 'Api/snapshotService';

const DeleteAction: React.FC<{
  optionValue: string;
  disabled?: boolean;
  callbackOnClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined;
  callbackOnComplete?: (snapshotId: string) => void | (() => void);
  size?: string;
  variant?: string;
  color?: string;
  className?: string;
  style?: CSSProperties;
}> = ({
  size = 'xs',
  variant = 'transparent',
  color = 'primary',
  optionValue,
  disabled = false,
  callbackOnComplete,
  callbackOnClick,
  className,
  style = { width: '100%', height: '100%' },
}) => {
  const confirmDeleteSnapshot = () => {
    modals.open({
      title: 'Delete Snapshot',
      children: (
        <Box>
          <Text size="sm">
            Are you sure you want to this delete Snapshot? <br />
            This will also remove the Assessment associated with the snapshot.
          </Text>
          <Flex style={{ width: '100%' }} align="center" justify="flex-end">
            <Group gap="sm">
              <Button
                variant="outline"
                size="compact-md"
                onClick={async () => {
                  modals.closeAll();
                  if (optionValue && parseInt(optionValue)) {
                    await SnapshotService.deleteSnapshots(
                      parseInt(optionValue)
                    );
                    if (callbackOnComplete) {
                      callbackOnComplete(optionValue);
                    }
                  }
                }}
              >
                Yes
              </Button>
              <Button
                variant="subtle"
                size="compact-md"
                onClick={modals.closeAll}
              >
                Cancel
              </Button>
            </Group>
          </Flex>
        </Box>
      ),
    });
  };

  return (
    <Tooltip label="Delete Snapshot">
      <ActionIcon
        size={size}
        disabled={disabled}
        variant={variant}
        color={color}
        className={className}
        onClick={(event) => {
          if (callbackOnClick) callbackOnClick(event);
          confirmDeleteSnapshot();
        }}
      >
        <IconTrash style={style} stroke={1.5} />
      </ActionIcon>
    </Tooltip>
  );
};

export default DeleteAction;
