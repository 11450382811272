const disableRibbonDropdowns = [
  'dropdown-item-toolbarGroup-Shapes',
  'dropdown-item-toolbarGroup-Insert',
  'dropdown-item-toolbarGroup-Edit',
  'dropdown-item-toolbarGroup-FillAndSign',
  'dropdown-item-toolbarGroup-Forms',
];
const disabledControls: Record<string, string[]> = {
  view: [
    'annotate',
    'ribbons',
    'selectToolButton',
    'toggleNotesButton',
    'toolsHeader',
    'contextMenuPopup',
    'annotationCommentButton',
  ],
  edit: [
    'annotationCommentButton',
    'toggleNotesButton',
    ...disableRibbonDropdowns,
  ],
};

export const getViewerInitConfig = (mode: string) => {
  return disabledControls[mode];
};

// https://docs.apryse.com/api/web/Core.DocumentViewer.html#FitMode
export const ViewerFitMode = {
  FITWIDTH: 'FitWidth',
  FITPAGE: 'FitPage',
  ZOOM: 'Zoom',
};
